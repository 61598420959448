.nav-pages {
	display: flex;
	flex-direction: column;
	padding: 22px 10px;
	gap: 39px;
	border-top: 1px solid #c7c7c7;

	@media (min-width: 768px) {
		padding: 0;
		gap: 20px;
		border: none;
	}

	@media (min-width: 1200px) {
		gap: 40px;
		padding: 0 12px;
	}
}

.nav-item-title {
	display: inline-block;
	font-size: 20px;
	font-weight: 900;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: -0.5px;
	text-align: left;
	color: $white;
	cursor: pointer;
	transition: all 0.3s;

	.nav-item.active &,
	&:hover {
		color: $yellow;
	}
}

.nav-item {
	position: relative;
}

.subnav {
	display: none;
	flex-direction: column;
	margin: 0 25px;
	padding-top: 21px;
	transition: all 0.3s;
	gap: 24px;
	position: relative;

	.is-open > & {
		display: flex;
	}

	&::before {
		content: '';
		display: block;
		height: calc(100% - 26px);
		width: 1px;
		background-color: #9e9faf;
		position: absolute;
		top: 26px;
		left: -14px;

		@media (min-width: 768px) {
			content: none;
		}
	}

	@media (min-width: 768px) {
		padding-top: 24px;
		margin: 0 20px;
		gap: 11px;
		overflow: hidden;
	}
}

.subnav-item-title {
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: left;
	color: $grey;
	cursor: pointer;
	transition: all 0.3s;

	.subnav-item.active &,
	.subnav-item:hover & {
		color: $yellow;
		font-weight: 900;
	}
}
