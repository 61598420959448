.order-container {
	width: 100%;
	max-width: 100%;
	background-color: #fff;
	padding: 2px 12px 50px 12px;
	height: fit-content;
	min-height: calc(100vh - 90px);

	@media (min-width: $breakpoint-sm) {
		min-width: fit-content;
		max-width: unset;
		padding: 4px 5px 20px 5px;
	}
}

.order-toggle-section {
	padding-bottom: 10px;
	border-bottom: 1px solid $silver;
	margin-bottom: 2px;

	&:not(:first-child) {
		padding-top: 10px;
	}
}

.order-toggle-header {
	display: grid;
	grid-template-columns: 1fr;
	gap: 10px;
	padding-top: 10px;
	margin-bottom: 18px;
	position: relative;

	@media (min-width: $breakpoint-sm) {
		display: flex;
		align-items: center;
		padding-left: 4px;
		margin-bottom: 12px;
	}

	.suplabel-grid {
		display: none;
	}

	.input-form-grid {
		min-width: 300px;
	}
}

.order-list {
	display: grid;
	grid-template-columns: 1fr;

	@media (min-width: $breakpoint-sm) {
		grid-template-columns: min-content 1fr min-content 1fr min-content 1fr;
		gap: 10px;
	}

	.input-form-grid,
	.select-custom {
		margin-bottom: 14px;

		@media (min-width: $breakpoint-sm) {
			margin-bottom: 0;
		}
	}

	.grid-header {
		display: none;
	}

	.label {
		display: flex;
		align-items: center;
		font-family: 'avenir', sans-serif;
		font-size: 14px;
		font-weight: 350;
		color: $black;
		padding-left: 0;
		white-space: nowrap;

		@media (min-width: $breakpoint-sm) {
			align-items: flex-start;
			padding-top: 4px;
		}
	}

	.suplabel-grid {
		display: none;
	}
}

.order-list-title {
	font-family: 'avenir', sans-serif;
	font-size: 16px;
	font-weight: 900;
	color: $black;
	margin-bottom: 14px;
}

.order-list-btn {
	width: 144px !important;
	margin-left: auto !important;
	margin-right: 0 !important;
	min-width: fit-content !important;

	@media (min-width: $breakpoint-sm) {
		grid-column: span 2;
	}
}

.order-find-container {
	padding-left: 8px;
	padding-right: 4px;

	@media (min-width: $breakpoint-sm) {
		padding-left: 14px;
	}
}

.order-btn {
	margin: 0 !important;
	min-width: fit-content !important;

	@media (min-width: $breakpoint-sm) {
		width: 144px !important;
	}
}

.order-toggle-subtitle {
	display: none;
	font-family: 'avenir', sans-serif;
	font-size: 16px;
	font-weight: 350;
	color: $black;

	@media (min-width: $breakpoint-sm) {
		display: block;
	}

	b {
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 900;
		color: $black;
	}
}

.order-table-container {
	max-height: calc(50vh - 240px);
	overflow: auto;
	border: 1px dashed #000000;
	margin-bottom: 10px;

	@media (min-width: $breakpoint-sm) {
		max-width: 100vw;
	}
}

.order-select {
	display: grid;
	grid-template-columns: 1fr;
	gap: 10px;

	@media (min-width: $breakpoint-sm) {
		display: flex;
		align-items: center;
	}

	.label {
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 900;
		color: $black;
	}

	.select-custom {
		min-width: 190px;
	}
}

.order-link {
	display: flex;
	align-items: center;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 900;
	color: $blue;
	margin-left: auto;
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 15px;

	@media (min-width: $breakpoint-sm) {
		position: static;
		right: auto;
		top: auto;
	}
}

.order-sum-list {
	display: grid;
	grid-template-columns: min-content 1fr min-content 1fr min-content 1fr;
	gap: 10px;
	padding-left: 14px;
	min-width: fit-content;
	width: 86%;
}

.order-info-list {
	display: grid;
	grid-template-columns: min-content 1fr min-content 1fr min-content 1fr;
	gap: 9px;
	padding-left: 14px;
	min-width: fit-content;
	width: 100%;

	.order-sum-label {
		padding-left: 10px;
	}
}

.order-sum-label {
	display: flex;
	align-items: center;
	font-family: 'avenir', sans-serif;
	font-size: 16px;
	font-weight: 900;
	color: $black;
	white-space: nowrap;
}

.order-sum-item {
	display: flex;
	align-items: center;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: $black;
}

.order-sum-select {
	display: flex;
	align-items: center;
	gap: 10px;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: $black;
	white-space: nowrap;

	.select-custom {
		min-width: 106px;
	}
}

.order-sum-header {
	font-family: 'avenir', sans-serif;
	font-size: 18px;
	font-weight: 900;
	color: #525252;
	grid-column: span 2;
	padding-bottom: 6px;
}

.order-details-title {
	font-family: 'avenir', sans-serif;
	font-size: 20px;
	font-weight: 900;
	color: $black;
	padding-top: 10px;
	padding-bottom: 12px;
	margin-bottom: 8px;
	border-bottom: 1px solid $silver;
}

.order-sum-btn {
	width: 200px !important;
	min-width: fit-content !important;
	margin-left: auto !important;
	margin-right: 0 !important;
}

.order-page {
	border: 1px dashed $silver;
	width: 70%;
	margin: 0 auto;
}

.order-page-header {
	font-family: 'SF Pro Text', sans-serif;
	font-size: 12px;
	font-weight: 700;
	color: #000000;
}

.order-page-title {
	font-family: 'Myriad', sans-serif;
	font-size: 36px;
	font-weight: 700;
	color: #525252;
}

.order-page-subtitle {
	font-family: 'Nunito', sans-serif;
	font-size: 36px;
	font-weight: 700;
	color: #525252;
}

.order-page-text {
	font-family: 'Nunito', sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: #525252;
}

.order-page-name {
	font-family: 'Nunito', sans-serif;
	font-size: 24px;
	font-weight: 700;
	color: #525252;
}

.order-modal {
	padding-top: 20px;
	padding-bottom: 50px;
}

.order-modal-btn {
	width: auto !important;
	padding: 0 11% !important;
}

.order-main-btn {
	width: auto !important;
	padding: 0 4.5% !important;
	margin: 0 !important;
}

.order-modal-btns {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 25px;

	.order-modal-btn {
		margin: 0 !important;
	}
}

.order-modal-form {
	display: grid;
	grid-template-columns: min-content 1fr;
	gap: 5px 10px;
	padding: 0 38px 24px 38px;

	.label-grid,
	.suplabel-grid,
	.grid-header {
		display: none;
	}

	textarea,
	input {
		&::placeholder {
			font-family: 'avenir', sans-serif;
			font-size: 12px;
			font-weight: 350;
			color: $grey;
		}
	}
}

.order-modal-label {
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: $black;
	white-space: nowrap;
	padding-top: 4px;

	span {
		font-family: 'avenir', sans-serif;
		font-size: 12px;
		font-weight: 350;
		color: $grey;
		margin-left: 2px;
	}
}

.order-label-required {
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: $red;
}

.order-modal-info {
	grid-column: span 2;
}

.order-modal-title {
	display: block;
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 800;
	color: $grey;
	padding-bottom: 2px;
}

.order-modal-list {
	display: grid;
	grid-template-columns: 1fr;
	gap: 3px;
	list-style: decimal;
	list-style-position: inside;
}

.order-modal-item {
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: $grey;

	b {
		font-family: 'avenir', sans-serif;
		font-size: 12px;
		font-weight: 800;
		color: $grey;
	}
}

.order-modal-err {
	grid-column: span 2;
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: $red;
	margin-top: -4px;
}

.order-header {
	display: flex;
	align-items: center;
	justify-content: space-around;
	gap: 50px;
	padding: 10px;

	@media (min-width: $breakpoint-sm) {
		justify-content: flex-start;
		padding: 0;
		padding-top: 10px;
	}
}

.order-header-item {
	font-family: 'avenir', sans-serif;
	font-size: 18px;
	font-weight: 350;
	color: $black;
	border-bottom: 3px solid transparent;
	padding-bottom: 3px;
	cursor: pointer;

	&.is-active {
		font-size: 18px;
		font-weight: 900;
		color: $black;
		border-color: $yellow;
	}

	&:hover {
		border-color: $yellow;
	}
}

.orderfull-container {
	width: 100%;
	max-width: 100%;
	background-color: #fff;
	padding: 7px;
	height: fit-content;
	min-height: calc(100vh - 90px);

	@media (min-width: $breakpoint-sm) {
		min-width: fit-content;
		max-width: unset;
		padding: 10px 8px 20px 3px;
	}
}

.orderfull-wrapper {
	@media (min-width: $breakpoint-sm) {
		padding-left: 26px;
	}
}

.orderfull-grid-list {
	display: grid;
	align-items: flex-end;
	grid-template-columns: 1fr;
	gap: 10px;
	padding-bottom: 11px;
	padding-top: 10px;

	@media (min-width: $breakpoint-sm) {
		grid-template-columns: repeat(2, minmax(min-content, 1fr)) repeat(
				2,
				minmax(min-content, 0.5fr)
			);
	}

	&.part-view {
		grid-template-columns: 1fr;

		@media (min-width: $breakpoint-sm) {
			grid-template-columns: 1fr repeat(4, minmax(min-content, 0.4fr));
		}
	}

	&.small-view {
		grid-template-columns: 1fr;

		@media (min-width: $breakpoint-sm) {
			grid-template-columns: minmax(min-content, 175px) minmax(
					min-content,
					150px
				);
		}
	}

	.label,
	.label-grid {
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 900;
		color: $black;
		padding-left: 0;
		white-space: nowrap;
	}

	.suplabel-grid {
		display: none;
	}
}

.orderfull-justify-list {
	display: grid;
	grid-template-columns: 1fr;
	align-items: flex-end;
	justify-content: space-between;
	gap: 10px;
	padding-bottom: 15px;
	margin-bottom: 10px;
	border-bottom: 1px solid $silver;

	@media (min-width: $breakpoint-sm) {
		display: flex;
	}

	div {
		&:first-child {
			min-width: 280px;
		}
	}

	.label,
	.label-grid {
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 900;
		color: $black;
		padding-left: 0;
		white-space: nowrap;
	}

	.suplabel-grid {
		display: none;
	}
}

.orderfull-btn-list {
	display: grid;
	grid-template-columns: 1fr;
	align-items: flex-end;
	gap: 12px;
	padding-bottom: 11px;

	@media (min-width: $breakpoint-sm) {
		display: flex;
	}

	span {
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 350;
		color: $black;

		b {
			font-weight: 900;
		}
	}
}

.orderfull-table-title {
	display: flex;
	align-items: center;
	font-family: 'avenir', sans-serif;
	font-size: 18px;
	font-weight: 900;
	color: $black;
	padding-bottom: 10px;
}

.order-table-subth {
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: $grey;
}

.order-custom-input {
	.label,
	.label-grid {
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 900;
		color: $black;
		padding-left: 0;

		span {
			font-size: 12px;
			font-weight: 350;
		}
	}

	.grid-header {
		display: none;
	}
}
