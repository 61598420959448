.media-container {
	width: 100%;
	max-width: 100%;
	background-color: $white;
	padding: 5px 10px;
	height: fit-content;
	min-height: calc(100vh - 90px);

	@media (min-width: $breakpoint-sm) {
		min-width: fit-content;
		max-width: unset;
		padding: 2px 6px 20px 6px;
	}
}

.media-header {
	display: grid;
	align-items: center;
	justify-content: space-between;
	grid-template-columns: 1fr;
	gap: 10px;
	width: 100%;
	padding-top: 16px;
	padding-bottom: 16px;

	@media (min-width: $breakpoint-sm) {
		grid-template-columns: 35vw 10vw;
		padding-bottom: 0;
	}

	.suplabel-grid {
		display: none;
	}

	.input-form-media {
		display: grid;
		grid-template-columns: 1fr;
		align-items: center;
		gap: 10px;

		@media (min-width: $breakpoint-sm) {
			grid-template-columns: min-content auto;
		}

		.label-grid {
			padding-top: 0 !important;
			margin-bottom: 0 !important;
			font-size: 20px;
			font-weight: 900;
		}
	}
}

.media-section {
	padding: 10px 0;
	margin: 10px 0;
	border-top: 1px solid $silver;
}

.media-section-title {
	font-family: 'avenir', sans-serif;
	font-size: 20px;
	font-weight: 900;
	color: $black;
	margin-top: 3px;
}

.media-list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	justify-content: space-between;
	margin-top: 10px;

	@media (min-width: $breakpoint-sm) {
		grid-template-columns: repeat(4, 1fr);
		justify-content: unset;
		padding-right: 20px;
		row-gap: 0;
	}

	@media (min-width: $breakpoint-md) {
		grid-template-columns: repeat(6, 1fr);
	}

	@media (min-width: $breakpoint-xl) {
		grid-template-columns: repeat(9, 1fr);
	}

	@media (min-width: $breakpoint-xxl) {
		grid-template-columns: repeat(12, 1fr);
	}
}

.media-item {
	position: relative;
	display: grid;
	gap: 14px;
	padding: 30px 10px 20px 10px;
	cursor: pointer;

	&:hover {
		background-color: $ivory;
	}

	&:first-child {
		.table-dropdown-list {
			right: auto;
			left: 0;
		}
	}

	@media (max-width: 767px) {
		&:nth-child(3n + 4) {
			.table-dropdown-list {
				right: auto;
				left: 0;
			}
		}
	}

	@media (min-width: $breakpoint-sm) {
		&:nth-child(4n + 5) {
			.table-dropdown-list {
				right: auto;
				left: 0;
			}
		}
	}

	@media (min-width: $breakpoint-md) {
		&:nth-child(4n + 5) {
			.table-dropdown-list {
				right: 0;
				left: auto;
			}
		}

		&:nth-child(6n + 7) {
			.table-dropdown-list {
				right: auto;
				left: 0;
			}
		}
	}

	@media (min-width: $breakpoint-xl) {
		&:nth-child(6n + 7) {
			.table-dropdown-list {
				right: 0;
				left: auto;
			}
		}

		&:nth-child(9n + 10) {
			.table-dropdown-list {
				right: auto;
				left: 0;
			}
		}
	}

	@media (min-width: $breakpoint-xxl) {
		&:nth-child(9n + 10) {
			.table-dropdown-list {
				right: 0;
				left: auto;
			}
		}

		&:nth-child(12n + 13) {
			.table-dropdown-list {
				right: auto;
				left: 0;
			}
		}
	}
}

.media-item-btn {
	display: none !important;
	position: absolute !important;
	top: 0;
	right: 5px;

	.media-item:hover & {
		display: block !important;
	}

	.table-dropdown-list {
		left: auto;
		right: 0;
	}
}

.media-img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
	margin: 0 auto;

	@media (min-width: $breakpoint-sm) {
		width: 100px;
		height: 100px;
		min-width: 100px;
	}
}

.media-img-name {
	display: block;
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: $grey;
	text-align: center;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.media-drop {
	border: 1px dashed $silver;
	padding: 20px;
	display: grid;
	gap: 6px;
	margin-top: 8px;
}

.media-drop-active {
	border: 1px dashed $yellow;
}

.media-drop-title {
	display: block;
	font-family: 'avenir', sans-serif;
	font-size: 16px;
	font-weight: 900;
	color: $black;
	text-align: center;
}

.media-drop-divider {
	display: block;
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: $grey;
	text-align: center;
}

.media-drop-btn {
	width: 20% !important;
	min-width: fit-content !important;
}

.media-modal-title {
	display: block;
	font-family: 'avenir', sans-serif;
	font-size: 16px;
	font-weight: 900;
	color: $black;
	text-align: center;
	margin-top: 10px;
}

.media-modal-subtitle {
	display: block;
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: $grey;
	text-align: center;
}

.media-modal-btns {
	display: grid;
	grid-template-columns: auto auto;
	gap: 30px;
	padding-top: 15px;
	width: 80%;
	margin: 0 auto;

	button {
		width: auto !important;
		margin: 0 !important;
	}
}

.media-modal-btn {
	display: grid;
	grid-template-columns: auto;
	gap: 30px;
	padding-top: 15px;
	width: 80%;
	margin: 0 auto;

	button {
		width: 162px !important;
		min-width: fit-content !important;
		margin: 0 auto !important;
	}
}

.media-watermark-modal {
}

.media-watermark-container {
	display: grid;
	grid-template-columns: min-content 1fr;
	gap: 23px;
	padding: 5px 20px;
}

.media-watermark-img {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 147px;
	max-width: 147px;
	height: 100%;
	object-fit: contain;
	padding-top: 5px;
}

.media-watermark-info {
	display: grid;
	align-items: flex-start;
	gap: 18px;

	.switch-text {
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 900;
		color: $black;
		order: 1;
		margin-right: 10px;
	}

	.switch-label {
		margin-right: 0;
		order: 2;
	}
}

.media-watermark {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: flex-start;
	gap: 5px;
}

.media-watermark-size {
	display: grid;
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: $grey;

	b {
		display: block;
		font-size: 16px;
		font-weight: 900;
		color: $black;
	}
}

.media-watermark-mark {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 100px;
	min-width: 100px;
}

.media-watermark-select {
	display: flex;
	align-items: center;
	gap: 28px;
	width: 100%;

	.label {
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 900;
		color: $black;
		white-space: nowrap;
		padding-left: 0;
	}

	.select-custom {
		width: 100%;
	}
}

.media-watermark-btn {
	width: 52% !important;
	min-width: fit-content !important;
	margin: 0 auto !important;
	margin-top: 20px !important;
}

.media-watermark-drop {
	width: fit-content;
	height: auto;
	cursor: pointer;
	position: relative;

	&:hover {
		.org-cover-btn {
			display: flex;
		}
	}

	input {
		position: absolute;
		z-index: -9;
		visibility: hidden;
		opacity: 0;
		height: 0;
		width: 0;
	}
}
