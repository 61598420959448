.campaign-container {
	width: 100%;
	max-width: 100%;
	background-color: #fff;
	padding: 7px 7px;
	height: fit-content;
	min-height: calc(100vh - 90px);

	@media (min-width: $breakpoint-sm) {
		min-width: fit-content;
		max-width: unset;
		padding: 4px 5px 20px 5px;
	}
}

.campaign-header {
	display: grid;
	grid-template-columns: 1fr;
	gap: 8px;
	width: 100%;
	border-bottom: 1px solid $silver;
	margin-bottom: 14px;
	padding: 0 5px 10px 5px;

	@media (min-width: $breakpoint-sm) {
		display: flex;
		align-items: center;
		padding: 10px 16% 5px 5px;
		gap: 10px;
	}
}

.campaign-details-header {
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	justify-content: space-between;
	padding: 2px 0 15px 0;
	gap: 15px;
	width: 100%;
	border-bottom: 1px solid $silver;
	margin-bottom: 25px;

	@media (min-width: $breakpoint-sm) {
		grid-template-columns: 42% 43%;
		padding: 10px 2px 5px 2px;
		gap: 10px;
		margin-bottom: 14px;
	}

	.input-form-autorow {
		display: grid;
		grid-template-columns: 1fr;
		gap: 10px;

		@media (min-width: $breakpoint-sm) {
			display: flex;
			align-items: flex-start;
		}
	}

	.label-grid {
		font-size: 20px;
		font-weight: 900;
		color: $black;
		padding-top: 2px !important;
	}

	.suplabel-grid {
		display: none;
	}
}

.campaign-select {
	display: grid;
	grid-template-columns: 1fr;
	width: 100%;
	gap: 8px;

	@media (min-width: $breakpoint-sm) {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	.label {
		font-family: 'avenir', sans-serif;
		font-size: 20px;
		font-weight: 900;
		color: $black;
		white-space: nowrap;
	}

	.select-custom {
		width: 100%;
	}
}

.campaign-table-wrapper {
	max-height: calc(100vh - 240px);
	overflow: auto;
	border: 1px dashed #000000;
	margin: 0 2px;

	@media (min-width: 768px) {
		max-width: calc(100vw - 40px);
		margin: 0 10px;
	}

	@media (min-width: 1440px) {
		width: 80%;
	}
}

.campaign-btn {
	width: 100% !important;
	min-width: fit-content !important;
	margin-top: 25px !important;

	@media (min-width: $breakpoint-sm) {
		width: 18vw !important;
	}
}

.campaign-modal-update {
	display: grid;
	align-items: center;
	grid-template-columns: 34% calc(66% - 20px);
	gap: 20px;
	margin-top: 22px;
	padding: 0 10% 50px 10%;
}

.campaign-modal {
	margin-top: 22px;
	padding-bottom: 50px;

	button {
		width: 145px !important;
		min-width: fit-content !important;
	}
}

.campaign-modal-delete {
	display: grid;
	align-items: center;
	grid-template-columns: calc(59% - 20px) 41%;
	gap: 20px;
	margin-top: 22px;
	padding: 0 10% 50px 10%;
}

.campaign-modal-copy {
	display: grid;
	grid-template-columns: 34% 66%;
	gap: 12px 0;
	padding: 5px 4% 22px 5%;

	.suplabel-grid,
	.grid-header {
		display: none;
	}

	.label-grid {
		padding-top: 2px;
	}

	.label-grid,
	.label {
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 900;
		color: $black;
	}
}

.campaign-modal-btns {
	display: grid;
	align-items: center;
	grid-template-columns: calc(59% - 20px) 41%;
	gap: 20px;
	padding: 0 8%;
}

.campaign-modal-tables {
	padding-top: 5px;
	display: grid;
	grid-template-columns: 1fr;
	gap: 10px;
}

.campaign-modal-table {
	max-height: 200px;
	overflow: auto;
	border: 1px dashed #000000;
	width: 58%;
	margin: 0 auto;
}

.campaign-table-btn {
	width: 145px !important;
	min-width: fit-content !important;
	margin: 15px auto 15px auto !important;
}

.campaign-section-header {
	.org-search-title {
		font-family: 'avenir', sans-serif;
		font-size: 20px;
		font-weight: 900;
		color: $black;
		margin-bottom: 10px;
	}
}

.campaign-section-wrapper {
	padding: 10px;
	border: 1px dashed $silver;
}

.campaign-section-fields {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;

	@media (min-width: $breakpoint-sm) {
		display: grid;
		grid-template-columns: min-content 1fr 1.2fr;
	}

	.input-form-autorow {
		.suplabel-grid,
		.grid-header {
			display: none;
		}
	}

	span {
		display: block;
		font-family: 'avenir', sans-serif;
		font-size: 14px;
		font-weight: 350;
		color: $black;
		white-space: nowrap;
	}

	.label-grid,
	.label {
		display: flex;
		align-items: center;
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 900;
		color: $black;
		white-space: nowrap;
	}
}

.campaign-input {
	grid-column: span 2;
	width: 100% !important;

	@media (min-width: $breakpoint-sm) {
		width: 70% !important;
	}
}

.campaign-section-grid {
	display: grid;
	align-items: center;
	grid-template-columns: 35px min-content 85px auto;
	gap: 10px;
	width: 100%;

	@media (min-width: $breakpoint-sm) {
		grid-template-columns: minmax(75px, 0.5fr) min-content minmax(150px, 1fr) minmax(
				150px,
				1fr
			);
	}
}

.campaign-section-form {
	display: grid;
	grid-template-columns: minmax(150px, 1fr) min-content;
	align-items: center;
	gap: 10px;
	width: 50%;

	.label {
		grid-column: span 2;

		@media (min-width: $breakpoint-sm) {
			grid-column: auto;
		}
	}

	@media (min-width: $breakpoint-sm) {
		grid-template-columns: min-content minmax(150px, 1fr) min-content;
		padding-left: 20px;
		width: 70%;
	}
}

.campaign-section-container {
	width: 100%;
	max-width: 100%;
	background-color: #fff;
	padding: 7px 10px;
	height: fit-content;
	min-height: calc(100vh - 90px);

	@media (min-width: $breakpoint-sm) {
		min-width: fit-content;
		max-width: unset;
		padding: 14px 8px;
	}
}

.campaign-section-test {
	display: grid;
	grid-template-columns: 1fr;
	gap: 10px;

	@media (min-width: $breakpoint-sm) {
		grid-template-columns: min-content 1fr;
		padding-right: 60%;
	}

	.grid-header,
	.suplabel-grid {
		display: none;
	}

	.label {
		display: flex;
		align-items: center;
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 900;
		color: $black;
		white-space: nowrap;
	}

	button {
		@media (min-width: $breakpoint-sm) {
			margin-top: 5px !important;
		}
	}
}

.campaign-toggle-section {
	padding-bottom: 15px;
	border-bottom: 1px solid $silver;
	margin-bottom: 15px;
}
