.marketing-section {
	display: flex;
	align-items: center;
	margin: 20px 0;

	.org-search-title {
		font-size: 18px;
		font-weight: 900;
	}

	@media (min-width: $breakpoint-sm) {
		padding-left: 20px;
	}
}

.marketing-section-header {
	display: flex;
	align-items: flex-end;
	gap: 10px;
	margin-left: 10px;

	.grid-header,
	.suplabel-grid {
		display: none;
	}

	.input-form-preset {
		display: flex;
	}
}

.marketing-edit {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	border: none;
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: $black;
	letter-spacing: 2px;
	cursor: pointer;
	text-shadow: 0px 3px rgba(44, 46, 69, 0.1);
}

.marketing-table {
	margin-left: 20px;
	margin-bottom: 10px;
	border-bottom: 1px solid $silver;

	.project-message-section {
		margin-top: 0;
		padding-top: 0;
		border-top: none;

		&:not(:last-child) {
			border-bottom: 1px solid $silver;
			margin-bottom: 10px;
		}
	}
}

.marketing-text {
	font-family: 'avenir', sans-serif;
	display: block;
	font-size: 12px;
	font-weight: 350;
	color: $grey;
	margin-bottom: 12px;
	line-height: 1.3;

	@media (min-width: $breakpoint-sm) {
		margin-bottom: 22px;
	}
}

.marketing-form {
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	gap: 12px;
	width: 100%;

	.input-form-grid {
		display: flex;
		flex-direction: column;
		gap: 0;
		width: 100%;

		@media (min-width: $breakpoint-sm) {
			align-items: center;
			flex-direction: row;
			gap: 10px;
		}

		.grid-header {
			width: auto;
		}
	}

	@media (min-width: $breakpoint-sm) {
		grid-template-columns: 1fr 1fr;
		width: max-content;
		gap: 20px;
	}
}

.marketing-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	gap: 12px;
	margin-bottom: 2px;
	padding-top: 10px;
	border-top: 1px solid $silver;

	@media (min-width: 768px) {
		justify-content: space-between;
		margin-bottom: 13px;
		margin-top: 10px;
	}
}

.marketing-container {
	width: 100%;
	max-width: 100%;
	background-color: #fff;
	padding: 14px 20px;
	height: fit-content;
	min-height: calc(100vh - 90px);

	@media (min-width: $breakpoint-sm) {
		min-width: fit-content;
		max-width: unset;
		padding: 22px 20px 20px 20px;
	}
}

.marketing-wrapper {
	margin-bottom: 10px;
	border-bottom: 1px solid $silver;
	padding-bottom: 20px;

	@media (min-width: $breakpoint-sm) {
		padding-left: 4px;
		padding-right: 3vw;
	}
}

.marketing-btn {
	min-width: fit-content !important;
	width: 100% !important;
	margin: 0 !important;

	@media (min-width: $breakpoint-sm) {
		width: 36% !important;
	}
}
