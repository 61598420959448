.breadcrumb {
	display: flex;
	gap: 20px;
}

.breadcrumb-list-mobile {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	min-height: 40px;
	gap: 50px;
}

.breadcrumb-list-item {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	border-bottom: 3px solid transparent;

	@media (min-width: 768px) {
		overflow: hidden;
	}

	&.header-active {
		font-weight: 900;

		.breadcrumb-item {
			font-weight: 900;
		}
	}
}

.breadcrumb-item {
	font-size: 18px;
	font-weight: 500;
	color: $black;
	letter-spacing: 0.9px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.breadcrumb-mob {
	display: flex;
	align-self: center;
	width: fit-content;
	margin: 18px 0;
}
