.presale-form-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 15px;
	padding-bottom: 8px;
	margin-bottom: 12px;
	border-bottom: 1px solid $silver;

	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		padding-left: 30px;
		padding-right: 27%;
	}
}

.presale-form {
	display: flex;
	align-items: center;
	gap: 10px;
	width: 100%;

	.label {
		white-space: nowrap;
	}

	.label-grid {
		padding-left: 4px;
	}

	.select-custom {
		width: 100%;
	}

	.react-datepicker-wrapper {
		width: auto;
	}

	.grid-header {
		width: auto;
	}
}

.presale-form-btn {
	font-family: 'avenir', sans-serif;
	font-weight: 350;
	font-size: 14px;
	color: $black;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	border: none;
	cursor: default;

	&:focus {
		outline: none;
	}
}

.presale-modal-btn {
	width: 36% !important;
	min-width: fit-content;
	margin-top: 10px !important;
}

.presale-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 12px;
	margin-bottom: 15px;

	.org-search-title {
		font-family: 'avenir', sans-serif;
		font-size: 18px;
		font-weight: 900;
		color: $black;
	}

	@media (min-width: 768px) {
		flex-direction: row;
		align-items: flex-end;
		justify-content: space-between;
	}
}

.presale-form-datepicker {
	display: grid;
	grid-template-columns: auto 1fr auto;
	row-gap: 0;

	.react-datepicker__tab-loop {
		order: 2;
	}

	.datepicker-error {
		grid-column-start: 2;
	}
}
