$white: #ffffff;
$ivory: #f7f7f7;
$black: #2c2e45;
$yellow: #fec52e;
$blue: #009bed;
$red: #f15d3f;

//grey
$silver: #c7c7c7;
$grey: #9e9faf;
$grey-light: #e2e2e2;
$grey-dark: #7d7e8e;
