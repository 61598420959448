.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 12px;
	container-type: inline-size;
}

.header-thumb {
	display: none;
	align-items: center;
	margin-right: 12px;

	&.is-show {
		display: flex;
		margin-bottom: 3px;
	}
}

.header-burger {
	@extend .burger-menu;
	width: 22px;
	height: 16px;
	background-position: center;
	background-repeat: no-repeat;
	margin-right: 10px;
	cursor: pointer;
	border: 0;
	background-color: transparent;
}

.heade-logo-mini {
	@extend .logo-mini;
	min-width: 60px;
	width: 100%;
	height: 20px;
	background-repeat: no-repeat;
	background-size: contain;

	@media (min-width: 768px) {
		min-width: unset;
		width: 24px;
		height: 24px;
	}
}

.header-content {
	display: flex;
	align-items: flex-end;
	width: 100%;

	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: min-content min-content minmax(auto, 78%);
		overflow: hidden;

		&.header-thumb-open {
			grid-template-columns: min-content minmax(auto, 70%);
		}
	}
}

.header-title {
	font-size: 16px;
	font-weight: 900;
	letter-spacing: 1.12px;
	margin: 0 auto;
	position: relative;
	border-bottom: 3px solid transparent;
	white-space: nowrap;

	@container (min-width: 1150px) {
		& {
			margin-right: 50px;
		}
	}

	@media (min-width: 768px) {
		font-size: 20px;
		margin-right: 20px;
	}
}

.header-control {
	display: flex;
	align-items: center;
	gap: 10px;

	& > * {
		cursor: pointer;
	}
}

.header-active {
	border-bottom: 3px solid $yellow !important;
}

.header-profile {
	display: flex;
	align-items: center;
	gap: 8px;

	@media (min-width: 768px) {
		margin: 0 5px;
	}
}

.header-user {
	display: none;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.769' height='16' viewBox='0 0 14.769 16'%3E%3Cpath d='M7.385 0a4.334 4.334 0 0 0-4.431 4.219 4.333 4.333 0 0 0 4.431 4.218 4.333 4.333 0 0 0 4.431-4.218A4.334 4.334 0 0 0 7.385 0zM12.9 11.194a6.481 6.481 0 0 0-4.531-1.819H6.4a6.481 6.481 0 0 0-4.529 1.819A6 6 0 0 0 0 15.531.482.482 0 0 0 .492 16h13.785a.481.481 0 0 0 .492-.468 6 6 0 0 0-1.869-4.338z' style='fill:%232c2e45'/%3E%3C/svg%3E");
	width: 15px;
	min-width: 15px;
	height: 16px;

	@media (min-width: 768px) {
		display: block;
	}
}

.header-search {
	display: none;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.444' height='16.25' viewBox='0 0 16.444 16.25'%3E%3Cpath data-name='Контур 1082' d='M7.267 14.145A6.663 6.663 0 0 1 .6 7.373a6.668 6.668 0 1 1 13.334 0 6.73 6.73 0 0 1-6.667 6.773zm0-12.8a6.033 6.033 0 0 0 0 12.064 6.033 6.033 0 0 0 0-12.064z' transform='translate(-.45 -.45)' style='stroke-width:.3px;fill:%232c2e45;stroke:%232c2e45'/%3E%3Cpath data-name='Контур 1083' d='M15.861 16.078c-.106 0-.212 0-.212-.106L11.2 11.633a.472.472 0 0 1 0-.529.472.472 0 0 1 .529 0l4.445 4.339a.472.472 0 0 1 0 .529.389.389 0 0 1-.313.106z' transform='translate(.077 .072)' style='stroke-width:.2px;fill:%232c2e45;stroke:%232c2e45'/%3E%3C/svg%3E");
	width: 16px;
	min-width: 16px;
	height: 16px;

	@media (min-width: 768px) {
		display: block;
	}
}

.header-profile-name {
	position: absolute;
	top: 0;
	left: 50vw;
	transform: translate(-50%, 0);
	font-family: 'avenir', sans-serif;
	font-size: 15px;
	font-weight: 900;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	order: 1;
	white-space: nowrap;

	@media (min-width: 768px) {
		order: unset;
		position: relative;
		transform: unset;
		top: auto;
		left: auto;
	}
}

.header-profile-thumb {
	min-width: 20px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	overflow: hidden;
	order: 2;

	@media (min-width: 768px) {
		min-width: 23px;
		width: 23px;
		height: 23px;
		order: unset;
	}
}

.header-profile-image {
	width: 100%;
	height: 100%;
	object-fit: fill;
}

.header-mob-title {
	display: grid;
	grid-template-columns: 22px 1fr 22px;
	align-items: center;
	background: #fff;
	padding: 10px 10px;
	margin-bottom: 10px;
}
