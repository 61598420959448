.preset {
	background-color: #fff;
	padding: 10px 15px;
	margin-top: 6px;
}

.preset-blank-main {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	padding-bottom: 20px;

	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: 1fr 2.7fr;
		gap: 20px;
	}
}

.preset-back {
	background-color: $ivory;
	padding: 0 5px 40px 5px;
}

.preset-title {
	font-family: 'avenir', sans-serif;
	display: block;
	font-size: 20px;
	font-weight: 900;
	color: $black;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;

	@media (min-width: 768px) {
		max-width: 500px;
	}
}

.preset-wrapper-header {
	padding: 12px 5px;
	padding-top: 0;
	width: 66% !important;
	column-gap: 20px;

	&.pres-details {
		.select-custom {
			width: 37%;
		}
	}
}

.preset-section-wrapper {
	display: grid;
	align-items: center;
	grid-template-columns: auto 1fr auto;
	column-gap: 12px;
	width: -webkit-fill-available;
	width: 100%;

	@media (min-width: 768px) {
		width: 34%;
	}

	.label {
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 900;
		color: $black;
	}

	.input-error {
		grid-column-start: 2;
	}
}

.preset-journey {
	border-top: 1px solid $silver;
	padding-top: 18px;
	padding-bottom: 5px;
}

.presets-journey-list {
	display: flex;
	flex-direction: column;
	padding: 10px 4px 2px 4px;
	border: 0.5px solid $grey;
	gap: 5px;
	max-height: 94px;
	max-width: 100%;
	overflow: auto;

	&.is-empty {
		padding: 6px 4px 4px 4px;
	}

	@media (min-width: 768px) {
		max-width: 270px;
		height: 287px;
		max-height: calc(90vh - 450px);
		min-height: 100px;
	}
}

.presets-journey-item {
	display: block;
	width: 100%;
	max-width: 100%;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: #000;
	cursor: pointer;
	padding-bottom: 5px;
	border-bottom: 2px solid transparent;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 23px;
	min-height: 23px;
	white-space: nowrap;

	&.is-active {
		font-weight: 900;
		border-color: $yellow;
	}

	&:hover {
		border-color: $yellow;

		.presets-journey-list.is-empty & {
			border-color: transparent;
		}
	}

	.presets-journey-list.is-empty & {
		font-size: 12px;
		font-weight: 350;
		color: #f15635;
		padding-bottom: 0;
		height: auto;
		min-height: unset;
	}
}

.preset-header-img {
	width: 100%;
	object-fit: contain;
}

.preset-info {
	display: block;
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: $black;
	margin: 12px 0;
	line-height: 1.3;
	letter-spacing: 0.1px;
}

.preset-blank-form {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 10vh;
	gap: 13px;

	@media (min-width: 768px) {
		width: 280px;
		min-width: 280px;
		padding-left: 12px;
	}
}

.preset-project-form {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 34px;
	gap: 13px;

	@media (min-width: 768px) {
		width: auto;
	}
}

.preset-form {
	padding-top: 15px;
	width: 100%;

	@media (min-width: 768px) {
		padding-left: 5px;
	}
}

.preset-form-layout {
	display: flex;
	flex-direction: column;
	gap: 10px;

	@media (min-width: 768px) {
		padding-left: 25px;
	}
}

.preset-form-wrapper {
	padding: 18px 26px;
}

.preset-placeholder {
	display: block;
	height: 60px;
	min-height: 60px;
	width: 100%;
	background-color: $grey-dark;
	margin: 5px 0;
}

.preset-placeholder-back {
	display: block;
	min-height: 50vh;
	width: 100%;
	background-color: #fff;
	margin: 5px 0;
}

.preset-grid {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 10px;
	white-space: nowrap;

	.input-form-content {
		position: relative;

		.grid-error {
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	@media (min-width: 768px) {
		flex-direction: row;
		align-items: flex-end;
	}

	.switch {
		.switch-label {
			margin-right: 8px;
			min-width: 28px;
		}

		.switch-text {
			white-space: nowrap;
			font-family: 'avenir', sans-serif;
			font-size: 14px;
			font-weight: 350;
			color: $black;
		}
	}
}

.preset-expires {
	&.input-form-autorow,
	.input-form-content {
		width: fit-content;
	}

	.input-grid {
		width: 50px;
		min-width: 50px;
		text-align: right;
	}

	.label-grid,
	.label {
		padding-left: 0;
	}
}

.preset-days {
	display: flex;
	align-items: center;
	gap: 10px;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: $black;

	.select-custom {
		min-width: 75px;
	}
}

.preset-header-border {
	border-top: 1px solid $silver;
	padding-top: 10px;

	.org-section-menu {
		padding-left: 0;
		padding-bottom: 10px;
	}

	&.project-section {
		border-bottom: none;
	}
}

.preset-journey-symbol {
	margin-bottom: 12px;

	b {
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 900;
		color: $black;
	}
}

.preset-message-marketing {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	column-gap: 10px;
	row-gap: 10px;
	padding-top: 12px;

	@media (min-width: 768px) {
		display: grid;
		align-items: center;
		grid-template-columns: max-content 1fr;
		row-gap: 15px;
		width: 65%;
	}
}

.preset-message {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	column-gap: 10px;
	row-gap: 10px;
	padding-top: 12px;

	@media (min-width: 768px) {
		padding-right: 50%;
	}

	.project-preset-step & {
		@media (min-width: 768px) {
			padding-right: 0;
		}
	}

	.project-textarea-label,
	.input-form-autorow {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 5px;

		@media (min-width: 768px) {
			display: grid;
			grid-template-columns: 200px 1fr;
		}
	}

	.suplabel-grid {
		display: none;

		@media (min-width: 768px) {
			display: flex;
		}
	}

	.textarea-preset {
		width: 100%;
	}

	.label,
	.label-grid {
		white-space: unset;
		font-family: 'avenir', sans-serif;
		font-size: 14px;
		font-weight: 900;
		color: $black;
		padding-left: 0;
	}
}

.preset-textarea-label {
	align-self: flex-start;
}

.preset-form-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: column;
	font-family: 'avenir', sans-serif;
	font-size: 20px;
	font-weight: 900;
	color: $black;
	gap: 10px;

	@media (min-width: 768px) {
		align-items: center;
		flex-direction: row;
		gap: 0;
	}

	.switch-label {
		margin-right: 8px;
		min-width: 28px;
	}

	.switch-text {
		white-space: nowrap;
		font-family: 'avenir', sans-serif;
		font-size: 14px;
		font-weight: 350;
		color: $black;
	}
}

.preset-form-marketing {
	margin-top: 12px;
}

.preset-details-img {
	width: 100%;
	object-fit: contain;
}

.preset-project-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10px;
	margin-bottom: 16px;
}

.preset-date-wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 12px;
	margin-bottom: 15px;
}

.preset-date {
	display: grid;
	grid-template-columns: auto 1fr 120px;
	align-items: center;
	column-gap: 5px;

	.grid-error {
		grid-column-start: 3;
	}
}

.preset-scroll {
	max-height: 51vh;
	overflow-y: auto;
}

.preset-timeline {
	width: 100%;
	object-fit: contain;
	border: 1px dashed $silver;
	padding: 10px;
}

.preset-details-header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	gap: 12px;
	margin-bottom: 15px;

	@media (min-width: 768px) {
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 26px;
		margin-top: 10px;
	}
}

.preset-header-double {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.switch-label {
		margin-right: 10px;
	}

	.switch {
		display: none;

		@media (min-width: $breakpoint-sm) {
			display: flex;
		}
	}
}

.preset-modal {
	display: flex;
	flex-direction: column;
	gap: 7px;
	padding: 10px 0;

	@media (min-width: 768px) {
		gap: 10px;
		padding: 0 18px;
	}

	.org-modal-btn {
		margin-top: 25px !important;
	}

	.input-form-row {
		grid-template-columns: min-content auto;
		width: 90%;
		gap: 10px;
		margin: 0 auto;
		margin-top: 6px;
		align-items: center;

		.label-grid {
			margin-top: 0;
		}

		.suplabel-grid {
			display: none;
		}
	}
}

.preset-header-form {
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	flex-direction: column;
	width: 100%;
	border-bottom: 1px solid $silver;
	margin-bottom: 10px;
	padding-top: 14px;
	padding-left: 5px;
	padding-bottom: 5px;
	gap: 14px;

	.label {
		display: flex;
		align-items: center;
		gap: 2px;
		font-family: 'avenir', sans-serif;
		font-size: 20px;
		font-weight: 900;
		color: $black;
	}

	.input-form-autorow,
	.project-textarea-label {
		display: grid;
		grid-template-columns: 1fr;
		width: 100%;
		gap: 5px;

		.suplabel-grid {
			display: none;
		}

		.input-error {
			grid-column: unset;
		}
	}

	.label-grid,
	.label {
		padding-top: 0 !important;
		padding-left: 0 !important;
		margin: 0 !important;
	}

	@media (min-width: 768px) {
		padding-right: 12%;

		.input-form-autorow,
		.project-textarea-label {
			display: grid;
			grid-template-columns: min-content auto;
			margin-bottom: 0;

			.input-error {
				grid-column-start: 2;
			}
		}

		.label-grid,
		.label {
			width: 150px;
			min-width: 150px;
		}

		.input-form-autorow {
			width: 44%;
		}
	}
}

.preset-header-input {
	.label-grid {
		font-size: 20px;
		font-weight: 900;
	}
}

.preset-details-back {
	width: 100%;
	min-width: 100%;
	background-color: $white;
	padding: 0 5px 40px 5px;

	@media (min-width: 768px) {
		width: fit-content;
	}
}

.preset-form-sticky {
	padding-top: 0;

	@media (min-width: 768px) {
		position: relative;
		max-height: calc(100vh - 170px);
		overflow: auto;
	}
}

.preset-header-sticky {
	padding-top: 15px;

	@media (min-width: 768px) {
		position: sticky;
		top: 0;
		background-color: $white;
		z-index: 1;
	}
}

.preset-placeholder-sticky {
	padding: 0 5px;
	background-color: $ivory;
}

.preset-project-container {
	width: 100%;
	max-width: 100%;
	background-color: #fff;
	padding: 10px;
	height: fit-content;
	min-height: calc(100vh - 90px);

	@media (min-width: $breakpoint-sm) {
		min-width: fit-content;
		max-width: unset;
		padding: 8px 8px 20px 8px;
	}
}
