.journey-header {
	display: flex;
	flex-direction: column;

	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: 51% 26%;
		justify-content: space-between;
		padding-top: 15px;
		padding-bottom: 18px;
		gap: 20px;
	}
}

.journey-header-select {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 16px;
	padding: 10px 2px 18px 2px;

	@media (min-width: 768px) {
		flex-direction: row;
		gap: 10px;
		padding: 0;
	}
}

.journey-mob-hide {
	display: none !important;

	@media (min-width: 768px) {
		display: flex !important;
	}
}

.journey-mob-show {
	display: flex !important;
	margin-top: 24px !important;

	@media (min-width: 768px) {
		display: none !important;
	}
}
