.price {
	width: 100%;
	min-width: fit-content;
	background-color: #fff;
	padding: 10px 10px 80px 10px;
	position: relative;
	height: fit-content;

	@media (min-width: $breakpoint-sm) {
		padding: 6px 8px;
		min-height: calc(100vh - 100px);
	}

	&.org-details {
		padding-right: 20px;
	}
}

.price-container-list {
	.price-search-container,
	.price-search-container.price-sub-search {
		margin-top: 14px;
		padding-top: 14px;
		padding-bottom: 5px;
		border-top: 1px solid $silver;

		&:first-child {
			margin-top: 0;
			padding-top: 2px;
			border-top: none;
		}
	}
}

.price-table-name {
	font-weight: 900;
	font-size: 14px;
	color: #009bed;
	cursor: pointer;
}

.table-value {
	display: block;
	max-width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}

.table-image {
	thead {
		th {
			&:first-child {
				width: 15% !important;
				max-width: 15% !important;
			}

			&:nth-child(6) {
				width: 15% !important;
				max-width: 15% !important;
			}
		}
	}

	tbody {
		td {
			&:first-child {
				width: 15% !important;
				max-width: 15% !important;
			}

			&:nth-child(6) {
				width: 15% !important;
				max-width: 15% !important;
			}
		}
	}
}

.price-table {
	.table {
		max-height: 300px;
	}

	.thead {
		.th {
			border-bottom: 1px solid #000000;
		}
	}

	.th {
		font-size: 16px;
		font-weight: 900;
		color: #2c2e45;
	}

	.td {
		color: #2c2e45;
		font-size: 14px;
		font-weight: 350;
	}

	.td-center {
		text-align: center;
	}

	.tr-center {
		text-align: center;
	}

	.td-right {
		text-align: right;
	}

	.tr-right {
		text-align: right;
	}
}

.thead-empty {
	opacity: 0;
}

.td-center {
	text-align: center;
}

.tr-arrow {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: left;
	gap: 5px;
	cursor: pointer;

	span {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.tr-arrow-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 11px;
	min-width: 11px;
	height: 7px;
	object-fit: contain;

	&.is-active {
		transform: rotate(180deg);
	}
}

.tr-center {
	text-align: center;

	.tr-arrow {
		text-align: center;
		justify-content: center;
	}
}

.td-right {
	text-align: right;
}

.tr-right {
	text-align: right;
}

.price-table-link {
	font-size: 14px;
	font-weight: 350;
	color: #009bed;
	cursor: pointer;
}

.price-header {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 12px 0;
	gap: 10px;

	@media (min-width: $breakpoint-sm) {
		flex-direction: row;
		justify-content: space-between;
		align-items: stretch;
		gap: 0;
	}
}

.price-header-list {
	display: flex;
	align-items: center;
	padding-bottom: 15px;
	gap: 20px;
	width: 100%;
	border-bottom: 1px solid $silver;
	overflow-x: auto;

	@media (min-width: 768px) {
		padding-bottom: 0;
		padding: 0 45px 15px 45px;
		justify-content: space-between;
		overflow: hidden;
	}
}

.price-header-item {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	height: 100%;
	font-size: 18px;
	font-weight: 350;
	color: #2c2e45;
	position: relative;
	white-space: nowrap;
	cursor: pointer;

	@media (min-width: 768px) {
		white-space: unset;
	}

	&.is-disabled {
		color: $silver;
		cursor: default;
	}

	&:hover:not(.is-disabled) {
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: calc(100% + 4px);
			left: 0;
			width: 100%;
			height: 3px;
			background-color: #fec52e;
		}
	}

	&.is-active {
		font-weight: 900;

		&::after {
			content: '';
			display: block;
			position: absolute;
			top: calc(100% + 4px);
			left: 0;
			width: 100%;
			height: 3px;
			background-color: #fec52e;
		}
	}
}

.price-btn {
	position: absolute;
	left: 10px;
	bottom: 14px;
	width: calc(100% - 20px) !important;
	margin: unset !important;
	order: 1;

	@media (min-width: $breakpoint-sm) {
		position: static;
		padding: 0 50px !important;
		width: fit-content !important;
		order: unset;
	}
}

.price-options {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	padding: 0 12px;

	@media (min-width: 768px) {
		padding: 12px;
		flex-wrap: nowrap;
	}

	&.multi {
		border-top: 1px solid $silver;
		margin-top: 15px;
	}

	.switch-label {
		margin-right: 10px;
	}

	.switch-text {
		font-size: 14px;
		font-weight: 350;
	}
}

.price-options-title {
	display: block;
	font-size: 16px;
	font-weight: 900;
	color: #2c2e45;
	margin-right: 21px;
	white-space: nowrap;
}

.price-option-table {
	width: 100%;
	border-collapse: collapse;

	.input-error {
		position: absolute;
	}

	&.price-table-options {
		.price-option-th {
			&:last-child {
				min-width: 9vw;
			}
		}
	}

	&.price-table-delivery {
		.price-option-th {
			&:last-child {
				width: 60%;
			}
		}
	}

	&.price-imago-package {
		min-width: fit-content;

		.input-error {
			position: static;
		}

		.price-textarea-label {
			gap: 5px;
		}

		.price-textarea-container {
			display: flex;
			align-items: center;
			gap: 10px;

			.select-custom {
				width: 45%;
			}
		}

		.price-option-td {
			vertical-align: baseline;
		}

		.price-option-th {
			width: 1%;

			&:nth-child(2) {
				width: 13%;
				min-width: 200px;
			}

			&:nth-child(4) {
				width: 16%;
				min-width: 200px;
			}

			&:nth-last-child(2) {
				width: 23%;
			}
		}

		.price-td-container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			align-items: flex-start;
			width: 100%;
			gap: 10px;

			.price-option-td {
				width: auto !important;
				padding: 0;
			}
		}

		.price-imago-body {
			.price-option-td {
				vertical-align: top;

				&:first-child {
					vertical-align: middle;
				}
			}
		}
	}

	&.price-table-carte {
		min-width: fit-content;

		.input-error {
			position: static;
		}

		.price-option-th {
			&:nth-child(2) {
				width: 20%;
				min-width: 200px;
			}

			&:nth-child(3),
			&:nth-child(4) {
				width: 13%;
				min-width: 125px;
			}

			&:nth-last-child(2) {
				width: auto;
				min-width: 185px;
			}

			&:last-child {
				width: 36%;
			}
		}

		.price-td-container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			align-items: flex-start;
			width: 100%;
			gap: 10px;

			.price-option-td {
				width: auto !important;
				padding: 0;
			}
		}

		.price-option-td {
			vertical-align: top;

			&:first-child {
				vertical-align: middle;
			}
		}
	}

	&.price-table-packages {
		min-width: fit-content;

		.input-error {
			position: static;
		}

		.price-option-th {
			&:nth-child(2) {
				width: 14vw;
				min-width: 215px;
			}

			&:nth-child(3),
			&:nth-child(4) {
				width: 9vw;
				min-width: 140px;
			}

			&:nth-last-child(2) {
				width: 100%;
			}
		}

		.price-td-container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			align-items: flex-start;
			width: 100%;
			gap: 20px;

			.price-option-td {
				width: auto !important;
				padding: 0;
			}
		}

		.price-option-td {
			vertical-align: top;

			.select-custom {
				width: 36%;
				min-width: 185px;
			}

			&:first-child {
				vertical-align: middle;
			}
		}
	}

	&.price-table-backgrounds {
		min-width: fit-content;

		.price-option-th {
			padding: 2px 10px;
			width: 9vw;
			min-width: 125px;

			&:first-child {
				width: 1%;
				min-width: 52px;
			}

			&:nth-child(2) {
				width: 1%;
				min-width: 112px;
			}

			&:nth-child(3) {
				width: 14vw;
				min-width: 200px;
			}

			&:last-child,
			&:nth-last-child(2) {
				width: auto;
				min-width: unset;
			}
		}

		.price-option-td {
			padding: 20px 10px 5px 10px;

			&:nth-last-child(2) {
				.input {
					width: 9vw;
					min-width: 125px;
				}
			}

			.price-textarea-label {
				width: 32vw;
				min-width: 450px;
			}
		}
	}

	.price-option-th {
		font-size: 16px;
		font-weight: 350;
		color: #2c2e45;
		padding: 2px 5px;
		border-bottom: 1px solid $silver;
		white-space: nowrap;
		text-align: left;
		position: relative;

		b {
			font-weight: 350;
			color: #f15635;
		}
	}

	.price-option-td {
		padding: 10px 5px 0 5px;
		position: relative;

		&.price-td-preview {
			cursor: pointer;

			.price-btn-delete {
				display: none;
			}

			&:hover {
				background-color: $ivory;

				.price-btn-delete {
					display: flex;
					position: absolute;
					top: 0;
					right: 0;
				}
			}
		}

		&:first-child {
			width: 1%;
			white-space: nowrap;
			vertical-align: middle;
		}

		&:last-child {
			width: 1% !important;

			button {
				margin-left: auto !important;
				margin-right: 0 !important;
			}
		}

		&.price-td-arrow {
			// padding: 20px 0 5px 0;
		}

		.switch-label {
			margin-right: 10px;
		}

		.switch-text {
			font-size: 14px;
			font-weight: 350;
			white-space: nowrap;
		}
	}

	.price-option-sub {
		font-size: 12px;
		font-weight: 350;
		color: #9e9faf;
		margin-left: 5px;
	}
}

.price-arrow-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: auto !important;
	gap: 4px;
}

.price-container {
	@media (min-width: 768px) {
		padding-left: 20px;
	}
}

.price-form-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	gap: 12px;
	padding-bottom: 5px;
	border-bottom: 1px solid $silver;
	padding-top: 2px;

	@media (min-width: 768px) {
		flex-direction: row;
		align-items: center;
		gap: 50px;
		padding-right: 8em;
		padding-top: 0;
	}

	.label-grid {
		font-family: 'avenir', sans-serif;
		font-size: 20px;
		font-weight: 900;
		color: $black;
		padding-top: 0 !important;

		@media (min-width: 768px) {
			padding-top: 17px !important;
		}
	}

	.suplabel-grid {
		display: none;

		@media (min-width: 768px) {
			display: block;
		}
	}
}

.price-btn-table {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 20px;
	width: 20px;
	height: 10px;
	background-color: transparent;
	border: none;
	cursor: pointer;
	margin: 0 auto;

	&:focus {
		outline: none;
		cursor: pointer;
	}
}

.price-icon-table {
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;

	&.is-disabled {
		background-image: none;
		cursor: default;
	}
}

.price-btn-delete {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 25px;
	min-width: 25px;
	height: 25px;
	background-color: #fff;
	border: none;
	border-radius: 50%;
	box-shadow: 1px 2px 2px 0px #00000040;
	margin-left: auto;
	cursor: pointer;

	.price-icon-table {
		width: 10px;
		height: 10px;
	}

	&:focus {
		outline: none;
	}
}

.price-preview {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	position: relative;
	width: 100px;
	margin: 0 auto;

	.input-error {
		top: 100%;
	}
}

.price-size-preview {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 5px;
}

.price-preview-size {
	display: flex;
	align-items: center;
	font-weight: 350;
	font-size: 14px;
	color: $black;

	i {
		display: block;
		font-style: normal;
		font-weight: 350;
		font-size: 12px;
		color: $grey;
		margin-left: 5px;
	}
}

.price-preview-img {
	display: block;
	height: 100%;
	width: auto;
	object-fit: contain;
	max-width: 100px;
	max-height: 100px;
}

.price-preview-name {
	display: block;
	font-family: 'avenir', sans-serif;
	font-weight: 350;
	font-size: 12px;
	font-style: normal;
	color: #9e9faf;
}

.price-table-left {
	text-align: left;
}

.price-add-back {
	min-width: fit-content !important;
	width: 25% !important;
	margin-left: auto !important;
	margin-right: 0 !important;
	margin-top: 20px !important;
}

.price-add-type {
	min-width: fit-content !important;
	width: 30% !important;
	margin-right: auto !important;
	margin-left: 0 !important;
}

.price-prompt {
	display: flex;
	align-items: center;
	width: 100%;
	border-top: 1px solid $silver;
	border-bottom: 1px solid $silver;
	padding: 10px 10px 18px 10px;
	margin-top: 15px;
	margin-bottom: 10px;
}

.price-prompt-label {
	display: block;
	font-weight: 900;
	font-size: 16px;
	color: #9e9faf;

	i {
		font-weight: 400;
	}
}

.price-prompt-info {
	display: block;
	font-weight: 350;
	font-size: 12px;
	color: #9e9faf;
	margin-left: 28px;
}

.price-controls {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 15px;
	margin-bottom: 50px;
	padding-top: 10px;
	gap: 100px;
	width: 100%;
	border-top: 1px solid $silver;

	button {
		width: 160px !important;
		margin: 0 !important;
	}
}

.price-pack-title {
	display: flex;
	align-items: flex-end;
	font-weight: 900;
	font-size: 16px;
	color: $black;
	padding: 11px 30px;

	span {
		display: block;
		font-weight: 350;
		font-size: 12px;
		color: $grey;
		margin-left: 16px;
	}
}

.price-package-title {
	display: flex;
	align-items: flex-end;
	font-size: 18px;
	font-weight: 900;
	color: $black;
}

.price-search-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 3px;
	padding-bottom: 5px;

	@media (min-width: $breakpoint-sm) {
		gap: 10px;
		flex-direction: row;
		align-items: center;
	}

	.org-search-title {
		font-size: 18px;
	}

	&.price-sub-search {
		margin-top: 14px;
		padding-top: 14px;
		border-top: 1px solid $silver;
	}
}

.price-textarea {
	font-weight: 350;
	font-size: 12px;
	color: $black;
	border: 0.5px solid $grey;
	padding: 5px;
	width: 100%;
	height: 100px;
}

.price-textarea-label {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	font-weight: 350;
	font-size: 14px;
	color: $black;
}

.price-table-arrow {
	width: 11px;
	height: 7px;
	min-width: 11px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	cursor: pointer;
	transform: rotate(180deg);

	&.is-active {
		transform: rotate(0deg);
	}
}

.table-btn-menu {
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background-color: transparent;
	font-family: 'avenir', sans-serif;
	font-size: 16px;
	font-weight: 900;
	color: $black;
	letter-spacing: 1px;
	cursor: pointer;

	&:hover {
		color: $blue;
	}

	&:focus {
		color: $yellow;
		outline: none;
	}

	.price-option-td & {
		padding: 0 10px;
	}
}

.table-action-menu {
	min-height: 300px;

	tbody {
		min-height: 300px;
	}

	td {
		&:last-child {
			overflow: visible !important;
		}
	}
}

.table-dropdown {
	position: relative;
	overflow: visible !important;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: fit-content;
	margin-left: auto;
}

.table-dropdown-list {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 100%;
	right: 0;
	gap: 5px;
	border: 0.5px solid $grey;
	padding: 5px;
	background-color: $white;
	z-index: 3;
	width: max-content;

	.table-action-menu tr:last-child td:last-child &,
	.table-action-menu tr:nth-last-child(2) td:last-child & {
		bottom: calc(100% - 7px);
		top: unset;
	}

	.table-action-menu tr:first-child td:last-child & {
		top: 100% !important;
		bottom: auto !important;
	}

	.project-album-table & {
		left: auto;
		right: 100%;
	}

	.price-option-td & {
		left: auto;
		right: 0;
	}

	&.is-active {
		display: flex;
	}
}

.table-dropdown-item {
	display: flex;
	align-items: center;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: #000000;
	border-bottom: 3px solid transparent;
	border-top: 3px solid transparent;
	cursor: pointer;

	&:hover,
	&:focus {
		border-bottom-color: #fec52e;
	}
}

.price-multi-wrapper {
	border-bottom: 1px solid $silver;
	padding: 10px 0;
	margin-bottom: 15px;
	padding-left: 30px;
}

.price-multi-header {
	display: flex;
	align-items: flex-end;
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	font-style: normal;
	color: $grey;
	gap: 5px;

	b {
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 900;
		color: $grey;
	}

	i {
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-style: italic;
		font-weight: 400;
		color: $grey;
		margin-right: 10px;
	}
}

.price-multi-check {
	display: flex;
	align-items: center;
	padding-top: 12px;
	padding-left: 32px;
	gap: 15px;

	.switch {
		margin-top: 0;
		margin-bottom: 0;
	}

	.switch-label {
		margin-right: 10px;
	}

	.switch-text {
		font-family: 'avenir', sans-serif;
		font-size: 14px;
		font-weight: 350;
		color: $grey;
	}
}

.price-multi-price {
	display: flex;
	align-items: center;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: $grey;
	white-space: nowrap;
	gap: 10px;

	.suplabel-grid,
	.grid-header {
		display: none;
	}
}

.price-subtitle {
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: $grey;
	padding-left: 10px;

	@media (min-width: 768px) {
		padding-left: 0;
	}
}

.price-table-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: 10px;
	position: relative;

	.label {
		font-size: 14px;
		font-weight: 350;
	}

	&.labels {
		grid-template-columns: auto 1fr auto 1fr;
	}

	&.charge {
		grid-template-columns: auto 75px auto 1fr;
	}

	.input-error {
		top: 100%;
		left: 0;
	}
}

.price-btn-default {
	display: flex;
	align-items: center;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: $black;
	padding: 3px 10px;
	background-color: #f7f7f7bf;
	border: 1px solid #f7f7f7bf;
	margin-left: auto;
}

.price-title-container {
	display: grid;
	grid-template-rows: 1fr 1fr;
	padding-top: 10px;
	width: 100%;

	.label {
		font-size: 16px;
		font-weight: 350;
		margin-right: 10px;
	}

	.select-custom {
		width: 200px;
	}

	@media (min-width: 768px) {
		display: flex;
		align-items: center;
		padding-top: 0;
		width: auto;
	}
}

.price-package-table {
	display: flex;
	align-items: center;
	gap: 15px;

	.label {
		font-size: 14px;
		font-weight: 350;
		order: 2;
		padding-left: 0;
		margin-left: -10px;
	}

	.input {
		min-width: unset;
		width: 65px;
	}
}

.price-package-select {
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 350;
	gap: 5px;
	padding: 5px 0;

	i {
		font-size: 14px;
		font-weight: 400;
		color: $grey;
	}

	.input {
		min-width: unset;
		width: 65px;
	}
}

.price-pose-select {
	display: flex;
	align-items: center;
	gap: 10px;

	.label {
		font-size: 14px;
		font-weight: 350;
		white-space: nowrap;
	}

	span {
		font-size: 14px;
		font-weight: 350;
		color: $grey;
	}

	.input,
	.select-custom {
		width: 65px;
		min-width: 65px;
	}
}

.table-loader {
	width: 100%;
	padding: 20px;
	margin: 40px auto;
}

.price-header-controls {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 6px 0;
	gap: 50px;

	@media (min-width: $breakpoint-sm) {
		padding: 20px 32px;
		justify-content: flex-start;
	}
}

.price-not-found {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 20px 0;
	text-align: center;
}

.price-modal {
	display: flex;
	flex-direction: column;
	gap: 7px;
	padding: 10px 0;

	@media (min-width: 768px) {
		gap: 10px;
		padding: 18px 18px 0 18px;
	}
}

.price-image-loader {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-width: 100px;
	height: 80px;
}

.price-error-field {
	.form-container {
		position: relative;
	}

	.input-error {
		top: 100%;
	}
}

.price-response-options {
	display: flex;
	align-items: center;
	gap: 10px;

	.input {
		width: 240px;
	}

	.switch {
		margin: 0;
	}
}

.price-question-table {
	.price-option-td {
		padding: 5px 10px;
	}
}

.price-question-btn {
	margin-top: 6px;
	width: 88% !important;
	min-width: fit-content !important;
}

.package-carte-title {
	font-family: 'avenir', sans-serif;
	font-size: 18px;
	font-weight: 900;
	color: $black;
	display: flex;
	align-items: center;
	gap: 10px;

	.switch-label {
		margin-right: 10px;
	}

	.switch-text {
		font-family: 'avenir', sans-serif;
		font-size: 14px;
		font-weight: 350;
		color: $black;
	}
}

.price-carte-options {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;

	.price-textarea-label {
		gap: 0;
	}
}

.price-container-subtitle {
	display: block;
	font-family: 'avenir', sans-serif;
	font-size: 16px;
	font-weight: 900;
	color: $black;
	margin: 12px 0;
	padding-left: 10px;
}

.price-textarea-container {
	padding-left: 10px;
}

.price-imago-container {
	padding-top: 40px;
	padding-left: 35px;
}

.price-imago-toggle {
	border-bottom: 1px solid $silver;
	padding-bottom: 15px;
	margin-bottom: 10px;

	.price-add-back {
		margin-left: 0 !important;
		width: 200px !important;
		min-width: fit-content !important;
	}
}

.price-imago-btn {
	margin-top: 20px !important;
	margin-right: 0 !important;
	margin-left: 0 !important;
	width: 200px !important;
	min-width: fit-content !important;
}

.price-option-sup {
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: $grey;
	position: absolute;
	bottom: 100%;
	left: 5px;
	white-space: normal;
	width: 100%;
}

.price-option-sup-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 400;
	font-style: italic;
	color: $grey;
	position: absolute;
	bottom: calc(100% - 15px);
	right: 0;
}

.price-delivery-text {
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: $grey;
}

.price-byo {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	gap: 10px;
	margin-top: 20px;
	padding: 10px 0;
	border-bottom: 1px solid $silver;
	margin-bottom: 10px;
}

.price-byo-text {
	display: block;
	font-size: 12px;
	font-weight: normal;
	color: $grey;
}

.price-byo-btn {
	min-width: fit-content !important;
	width: 25% !important;
	margin: 0 !important;
}
