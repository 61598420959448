.timeline {
	padding: 10px 20px 10px 5px;
	margin: 10px 0;
	border: 1px dashed $silver;
}

.timeline-list {
	display: flex;
	flex-direction: column;
	gap: 25px;
}

%timeline {
	content: '';
	border-right: 10px solid;
	position: absolute;
	top: calc(100% - 5px);
	left: 72px;
	transform: translate(-50%, 0);
	height: 40px;
	width: 1px;
}

.timeline-item {
	display: flex;
	align-items: center;
	gap: 8px;
	position: relative;
	width: 100%;

	&::after {
		content: '';
		border-right: 5px dotted #d9d9d9;
		position: absolute;
		top: 100%;
		left: 72px;
		transform: translate(-50%, 0);
		height: 27px;
		width: 1px;
	}

	&.left {
		&-sale-red,
		&-sale-green,
		&-sale-purple,
		&-sale-blue,
		&-picture {
			&::before {
				@extend %timeline;
				left: 66px !important;
			}

			&::after {
				content: none;
			}
		}
	}

	&.right {
		&-sale-red,
		&-sale-green,
		&-sale-purple,
		&-sale-blue,
		&-picture {
			&::after {
				content: '' !important;
				@extend %timeline;
				left: 78px !important;
			}
		}
	}

	&.right {
		&-sale-red {
			&::after {
				border-color: #f15635;
			}
		}

		&-sale-green {
			&::after {
				border-color: #76ad5a;
			}
		}

		&-sale-purple {
			&::after {
				border-color: #8b47ad;
			}
		}

		&-sale-blue {
			&::after {
				border-color: #009bed;
			}
		}

		&-picture {
			&::after {
				border-color: $yellow;
			}
		}
	}

	&.left {
		&-sale-red {
			&::before {
				border-color: #f15635;
			}
		}

		&-sale-green {
			&::before {
				border-color: #76ad5a;
			}
		}

		&-sale-purple {
			&::before {
				border-color: #8b47ad;
			}
		}

		&-sale-blue {
			&::before {
				border-color: #009bed;
			}
		}

		&-picture {
			&::before {
				border-color: $yellow;
			}
		}
	}

	&.sale-red {
		&::after {
			@extend %timeline;
			border-color: #f15635;
		}
	}

	&.sale-green {
		&::after {
			@extend %timeline;
			border-color: #76ad5a;
		}
	}

	&.sale-purple {
		&::after {
			@extend %timeline;
			border-color: #8b47ad;
		}
	}

	&.sale-blue {
		&::after {
			@extend %timeline;
			border-color: #009bed;
		}
	}

	&.picture {
		&::after {
			@extend %timeline;
			border-color: $yellow;
		}
	}

	&:last-child {
		&::after {
			content: none;
		}
	}
}

.timeline-road {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 38px;
	min-width: 38px;
	height: 38px;
	border-radius: 50%;
	background-color: #d9d9d9;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: $black;
	position: relative;
	z-index: 1;

	&.sale-red {
		background-color: #f15635;
	}

	&.sale-green {
		background-color: #76ad5a;
	}

	&.sale-purple {
		background-color: #8b47ad;
	}

	&.sale-blue {
		background-color: #009bed;
	}

	&.picture {
		background-color: $yellow;
	}

	&.is-toggled {
		width: auto;
		height: auto;
		border-radius: 0;
		align-items: stretch;
		height: 100%;
		width: 100%;
		background-color: transparent;
	}
}

.timeline-roadmap {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;
	padding: 18px 14% 18px 10px;
	width: 100%;
	background-color: $ivory;
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;
	overflow: hidden;
	position: relative;
}

.timeline-roadmap-header {
	display: flex;
	align-items: center;
	font-family: 'avenir', sans-serif;
	font-size: 16px;
	font-weight: 900;
	color: $black;
	padding-left: 0;
	grid-column: span 2;
	white-space: nowrap;
	cursor: pointer;

	.timeline-road.is-open & {
		&::before {
			transform: rotate(0deg);
		}
	}

	&::before {
		content: '';
		display: flex;
		align-items: center;
		justify-content: center;
		background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_9191_9536)'%3E%3Cpath d='M9.87011 5.67599L5.50011 1.09399M1.16211 5.67699L5.53211 1.09399' stroke='%232C2E45' stroke-width='1.1' stroke-linecap='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9191_9536'%3E%3Crect width='11' height='7' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		width: 11px;
		min-width: 11px;
		height: 100%;
		margin-right: 10px;
		transform: rotate(180deg);
	}
}

.timeline-roadmap-subtitle {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 5px;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 400;
	font-style: italic;
	color: $black;
}

.timeline-roadmap-col {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.timeline-roadmap-list {
	display: flex;
	align-items: center;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: $black;
	padding-left: 40px;
	white-space: nowrap;

	b {
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight: 900;
		margin-right: 5px;
	}

	a {
		font-size: 14px;
		font-weight: 900;
		color: $blue;
		cursor: pointer;
	}
}

.timeline-roadmap-name {
	display: block;
	max-width: 250px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.timeline-indent-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;

	.timeline-road.icon-fill & {
		width: 100%;
		height: 100%;
	}
}

.timeline-road-indent {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	min-width: 40px;
	border-top-left-radius: 12px;
	border-bottom-left-radius: 12px;
	position: relative;

	.timeline-road.is-toggled.sale-red & {
		background-color: #f15635;
	}

	.timeline-road.is-toggled.sale-green & {
		background-color: #76ad5a;
	}

	.timeline-road.is-toggled.sale-purple & {
		background-color: #8b47ad;
	}

	.timeline-road.is-toggled.sale-blue & {
		background-color: #009bed;
	}
}

.timeline-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	min-width: 20px;
	height: 15px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.timeline-date {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: $black;
	white-space: nowrap;
	height: 100%;
	width: 45px;
}

.timeline-name {
	display: flex;
	align-items: center;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: $black;

	&.timeline-road-sale {
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 900;
		color: $black;
	}

	b {
		display: flex;
		align-items: center;
		font-family: 'avenir', sans-serif;
		font-size: 14px;
		font-weight: 900;
		margin-right: 5px;
		color: $black;
	}
}

.timeline-date-estimate {
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 400;
	font-style: italic;
	color: $black;
	position: relative;

	&::after {
		content: '*';
		position: absolute;
		right: -6px;
	}
}

.timeline-edit {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	border: none;
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: $black;
	letter-spacing: 2px;
	cursor: pointer;
	text-shadow: 0px 3px rgba(44, 46, 69, 0.1);
	position: absolute;
	top: 18px;
	right: 12px;
}
