.input {
	border: 0.5px solid #9e9faf;
	background-color: #fff;
	font-size: 12px;
	font-weight: 350;
	color: #2c2e45;
	border-radius: 2px;
	padding: 5px;
	height: 25px;
	width: 100%;

	&::placeholder {
		font-size: 12px;
		font-weight: 350;
		color: $silver;
	}

	&:focus {
		outline: 3px solid #fec52e;
		border-radius: 2px;
		border-color: transparent;
	}

	&:read-only {
		border-color: #9e9faf;
		background-color: #e2e2e2;
		color: #9e9faf;
		outline: none;
		cursor: default;

		&::placeholder {
			color: #9e9faf;
		}
	}

	&:disabled {
		background-color: #fff;
		border-color: #e2e2e2;

		&::placeholder {
			color: #e2e2e2;
		}
	}
}

.input-error {
	display: block;
	text-align: left;
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	color: #f15635;
	grid-column: span 2;
}

.wrapper-error {
	input {
		border-color: #f15635 !important;
	}
}

.select-error {
	.select__control {
		border-color: #f15635 !important;
	}
}

//grid

%grid-form {
	.label {
		display: flex;
		align-items: center;
		gap: 2px;
		font-size: 16px;
		font-weight: 900;
		color: $black;
		margin-bottom: 2px;
		white-space: nowrap;

		@media (min-width: 768px) {
			padding-left: 3px;
		}
	}

	.select-custom {
		width: 100%;
	}

	&.input-form-error {
		.input-grid {
			border-color: #f15635 !important;
		}

		.select-custom .select__control {
			border-color: #f15635 !important;
		}
	}
}

.input-form-grid {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	@extend %grid-form;
}

.input-form-row {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 5px;
	@extend %grid-form;

	.label-grid {
		margin-top: 16px;
	}

	.suplabel-grid {
		text-align: left;
		height: 14px;
	}

	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: minmax(min-content, 38%) 58%;
		gap: 10px;
	}

	.project-modal-group & {
		.suplabel-grid {
			display: none;
		}

		.label-grid {
			margin-top: 3px;
		}

		@media (min-width: 768px) {
			grid-template-columns: minmax(min-content, 115px) auto;
		}
	}
}

.input-form-autorow {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 5px;
	width: 100%;
	@extend %grid-form;

	.suplabel-grid {
		text-align: left;
	}

	.suplabel-grid,
	.sublabel-grid {
		height: 14px;
	}

	.grid-header {
		width: auto;
	}

	.label-grid {
		padding-top: 17px;
	}

	@media (min-width: 768px) {
		gap: 10px;
	}
}

.input-grid {
	border: 0.5px solid #9e9faf;
	background-color: #fff;
	font-size: 12px;
	font-weight: 350;
	color: #2c2e45;
	border-radius: 2px;
	padding: 5px;
	height: 25px;
	width: 100%;

	&::placeholder {
		font-size: 12px;
		font-weight: 350;
		color: $silver;
	}

	&:focus {
		outline: 3px solid #fec52e;
		border-radius: 2px;
		border-color: transparent;
	}

	&:disabled {
		background-color: #fff !important;
		border-color: #e2e2e2 !important;

		&::placeholder {
			color: #e2e2e2 !important;
		}
	}

	&:read-only {
		border-color: #9e9faf;
		background-color: #e2e2e2;
		color: #9e9faf;
		outline: none;
		cursor: default;

		&::placeholder {
			color: #9e9faf;
		}
	}
}

.grid-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 10px;
}

.label-grid {
	display: flex;
	align-items: center;
	gap: 2px;
	font-size: 16px;
	font-weight: 900;
	color: $black;
	margin-bottom: 2px;
	white-space: nowrap;

	@media (min-width: 768px) {
		padding-left: 3px;
	}
}

.grid-required {
	display: inline-block;
	font-size: 16px;
	font-weight: 900;
	color: #f15635;
}

.select-required {
	&.label {
		&::after {
			content: '*';
			font-size: 16px;
			font-weight: 900;
			color: #f15635;
		}
	}
}

.suplabel-grid {
	display: block;
	font-size: 12px;
	font-weight: 350;
	color: $grey;
	text-align: right;
}

.sublabel-grid {
	display: block;
	font-size: 12px;
	font-weight: 350;
	color: $grey;
	text-align: left;
	margin-top: 1px;
}

.grid-error {
	display: block;
	font-size: 12px !important;
	font-weight: normal !important;
	font-style: normal !important;
	color: #f15635 !important;
}

.input-form-content {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.textarea-preset {
	border: 0.5px solid $grey;
	padding: 5px;
	border-radius: 2px;
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: $black;
	height: 100px;

	&::placeholder {
		font-size: 12px;
		font-weight: 350;
		color: $silver;
	}

	&:focus {
		outline: 3px solid #fec52e;
		border-radius: 2px;
		border-color: transparent;
	}

	&:read-only {
		border-color: #9e9faf;
		background-color: #e2e2e2;
		color: #9e9faf;
		outline: none;
		cursor: default;

		&::placeholder {
			color: #9e9faf;
		}
	}

	&:disabled {
		background-color: #fff;
		border-color: #e2e2e2;

		&::placeholder {
			color: #e2e2e2;
		}
	}
}

.input-form-preset {
	display: grid;
	grid-template-columns: 145px auto;
	gap: 12px;
	align-items: flex-start;
	width: 100%;
	height: 100%;

	.suplabel-grid {
		text-align: left !important;
	}

	.suplabel-grid {
		height: 14px;
		min-height: 14px;
	}

	.select-custom {
		margin-top: 16px;
	}

	.label,
	.label-grid {
		white-space: nowrap;
		margin-top: 16px;
	}

	&.broadcast-input {
		grid-template-columns: auto 1fr;
	}

	&.broadcast-include-input {
		display: flex;
		align-items: center;
		flex-direction: row;
		width: 100%;

		@media (min-width: $breakpoint-sm) {
			display: grid;
			grid-template-columns: 204px 1fr;
			width: 50%;
		}

		.label-grid {
			margin-top: 0;
		}

		.suplabel-grid {
			display: none;
		}
	}
}

.input-form-price {
	display: grid;
	grid-template-columns: 150px auto;
	gap: 0 12px;
	align-items: flex-start;
	width: 100%;
	height: 100%;

	.label-grid,
	.grid-header {
		margin: 0;
		height: 100%;
	}

	.input-error {
		grid-column-start: 2;
	}

	.price-modal-large & {
		grid-template-columns: 175px auto;
	}
}

.input-checkbox {
	padding: 0;
	height: initial;
	width: initial;
	margin-bottom: 0;
	display: none;
	cursor: pointer;
}

.input-checkbox-container {
	margin-top: 12px;
	margin-bottom: 28px;

	.input-checkbox:checked + .input-checkbox-label:before {
		background-color: $red;
	}

	.input-checkbox:checked + .input-checkbox-label:after {
		content: '';
	}

	.input-checkbox:disabled + .input-checkbox-label:before {
		border-color: $grey-light;
		background-color: $ivory;
	}

	.input-checkbox:disabled + .input-checkbox-label {
		cursor: default;
	}
}

.input-checkbox-label {
	display: grid;
	grid-template-columns: auto auto;
	align-items: center;
	gap: 0 10px;
	position: relative;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: $black;
	margin: 0 auto;
	width: fit-content;
	cursor: pointer;

	&::before {
		content: '';
		display: flex;
		align-items: center;
		justify-content: center;
		width: 15px;
		min-width: 15px;
		height: 15px;
		background-color: $ivory;
		border: 1px solid $grey;
	}

	&::after {
		content: none;
		width: 10px;
		min-width: 10px;
		height: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_61_1033)'%3E%3Cpath d='M2 5L4.25 7.5L8.5 1' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_61_1033' x='0.75' y='0.249908' width='9.00006' height='9.00009' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='0.5'/%3E%3CfeGaussianBlur stdDeviation='0.25'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_61_1033'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_61_1033' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		position: absolute;
		top: 50%;
		left: 2px;
		transform: translate(0, -50%);
	}
}
