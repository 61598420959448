.chart {
	padding-right: 14px;
	padding-bottom: 2px;
	overflow: hidden;
	flex: none;
}

.chart-container {
	display: flex;
	height: 190px;
}

.chart-list {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(0, max-content));
	justify-content: center;
	align-items: center;
	position: relative;
	gap: 0.7vw;
	padding-left: 18px;
	padding-top: 10px;
	padding-bottom: 5px;
}

%chart-date {
	position: absolute;
	display: block;
	font-size: 9px;
	font-weight: normal;
	line-height: 1.2;
	letter-spacing: 0.45px;
	top: 2px;

	&::before {
		content: none;
	}
}

.chart-item {
	display: flex;
	align-items: center;
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: normal;
	color: $grey;
	line-height: 1.2;
	letter-spacing: 0.63px;

	&::before {
		content: '';
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 15px;
		width: 15px;
		height: 3px;
		border-radius: 3px;
		margin-right: 5px;
	}

	&.sends {
		&::before {
			background-color: #9e9faf;
		}
	}

	&.opens {
		&::before {
			background-color: #f15635;
		}
	}

	&.clicks {
		&::before {
			background-color: #2c2e45;
		}
	}

	&.logins {
		&::before {
			background-color: #fec52e;
		}
	}

	&.orders {
		&::before {
			background-color: #009bed;
		}
	}

	&.sales {
		width: 100%;
		margin-left: auto;
		grid-column-start: none;

		&::before {
			background-color: #76ad5a;
			min-width: 5px;
			width: 5px;
			border-radius: 5px;
			height: 15px;
			margin-right: 5px;
		}

		&::after {
			content: '';
			display: flex;
			align-items: center;
			justify-content: center;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.001' height='13.001' viewBox='0 0 13.001 13.001'%3E%3Cpath d='M12.967 11.14a.508.508 0 1 0-.994.206.532.532 0 0 1-.108.445.516.516 0 0 1-.406.193H1.541a.511.511 0 0 1-.406-.193.534.534 0 0 1-.108-.445A5.611 5.611 0 0 1 6.365 6.9h.27a5.585 5.585 0 0 1 4.512 2.48 4.162 4.162 0 0 1 .5.824c.258.561.493 1.32.52 1.338a.506.506 0 0 0 .781-.542 12.245 12.245 0 0 0-.46-1.276 6.485 6.485 0 0 0-.5-.91 6.607 6.607 0 0 0-3.422-2.6 3.453 3.453 0 1 0-4.134 0 6.6 6.6 0 0 0-4.4 4.922A1.54 1.54 0 0 0 1.541 13h9.918a1.541 1.541 0 0 0 1.508-1.861zM4.062 3.452a2.438 2.438 0 1 1 2.561 2.435h-.246a2.442 2.442 0 0 1-2.315-2.434z' transform='translate(.001)' style='fill:%239e9faf'/%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			width: 13px;
			height: 13px;
			margin-left: 10px;
		}
	}

	&.start-date {
		@extend %chart-date;
		left: 20px;
	}

	&.end-date {
		@extend %chart-date;
		right: 20px;
	}
}
