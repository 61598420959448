.overlay {
	position: fixed;
	z-index: 10;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	background-color: rgba(0, 0, 0, 0.2);
}

.modal {
	background-color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	z-index: 11;
	box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.08);
	padding: 52px 14px 24px 14px;
	max-height: 90vh;
	overflow-y: auto;

	@media (min-width: 768px) {
		width: 550px;

		&.modal-fixed {
			max-width: 550px;
		}
	}

	&.full {
		width: 95vw;
		max-width: 95vw;
	}

	.select-custom {
		.select__menu-list {
			max-height: 80px;
			overflow: auto;
		}
	}
}

.modal-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	position: relative;
}

.modal-body {
	width: 100%;

	&.modal-no-subtitle {
		padding-top: 5px;
	}
}

.modal-heading {
	font-family: 'avenir', sans-serif;
	font-size: 20px;
	font-weight: 900;
	font-style: normal;
	text-align: center;

	@media (min-width: $breakpoint-sm) {
		white-space: nowrap;
	}
}

.modal-subtitle {
	display: block;
	font-size: 12px;
	font-weight: 350;
	color: $grey;
	margin: 5px 0;
	text-align: center;
	width: 100%;
	max-width: 430px;
	line-height: 1.3;

	.modal-info & {
		font-family: 'avenir', sans-serif;
		font-size: 14px;
		font-weight: 350;
		color: $black;
		line-height: 1.3;
	}
}

.modal-btn {
	position: absolute;
	top: -42px;
	right: -4px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 25px;
	min-width: 25px;
	height: 25px;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: 1px 2px 2px 0px #00000040;
	font-size: 20px;
	font-weight: 350;
	background-color: transparent;
	border: none;
	cursor: pointer;

	&:focus {
		outline: none;
	}
}

.modal-prospect-heading {
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'avenir', sans-serif;
	font-size: 16px;
	font-weight: 900;
	color: $black;
	text-align: center;
}

.modal-prospect-value {
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: $black;

	b {
		display: block;
		font-weight: 900;
		margin-right: 5px;
	}
}

.modal-prospect-info {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.modal-prospect-notice {
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: $grey;
	margin-top: 10px;
}

.modal-prospect-footer {
	display: grid;
	grid-template-columns: 1fr 1.5fr;
	width: 70%;
	margin: 0 auto;
	margin-top: 30px;
	gap: 30px;

	&.is-single {
		grid-template-columns: 1fr;
		width: 50%;
	}

	button {
		width: 100% !important;
		margin: 0 !important;
		margin-top: 0 !important;
	}
}

.modal-prospect-subtitle {
	margin-top: 20px;
	margin-bottom: 35px;
	padding: 0 10%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.modal-table {
	margin-top: 20px;
	border: 1px dashed $black;
}

.modal-upload {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px 18px 10px 18px;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: $black;
	gap: 10px;

	b {
		display: flex;
		align-items: center;
		font-weight: 900;
	}

	span {
		display: flex;
		align-items: center;
		font-size: 12px;
		font-weight: 350;
		color: $grey;
		margin-left: -5px;
	}
}

.modal-upload-progress {
	display: block;
	height: 5px;
	width: 70%;
	margin: 0 auto;
	background-color: $silver;
	border-radius: 4px;
	position: relative;
	overflow: hidden;
}

.modal-upload-bar {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
	display: block;
	height: 5px;
	background-color: #f15635;
	border-radius: 4px;
	transition: width 1s ease-in-out;
}

.modal-inline-preset {
	display: grid;
	grid-template-columns: 142px auto;
	column-gap: 12px;

	.input-error {
		grid-column-start: 2;
	}
}

.modal-link {
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 900;
	color: $blue;
}

.modal-plan {
	min-width: max-content;
	overflow-x: hidden;
}
