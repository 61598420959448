.accing-list {
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	gap: 14px;
	padding-left: 25px;

	@media (min-width: $breakpoint-sm) {
		display: flex;
		flex-wrap: wrap;
		gap: 10px 3.5vw;
		padding-left: 9px;
	}
}

.accing-list-wrapper {
	display: grid;
	grid-template-columns: 1fr;
	gap: 10px;
	padding-top: 12px;

	@media (min-width: $breakpoint-sm) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 16px;
		gap: 20px;
	}
}

.accing-item {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	gap: 10px;
	font-family: 'avenir', sans-serif;
	font-size: 16px;
	font-weight: 900;
	color: $black;
	white-space: nowrap;

	@media (min-width: $breakpoint-sm) {
		justify-content: unset;
	}

	span {
		font-family: 'avenir', sans-serif;
		font-size: 14px;
		font-weight: 350;
		color: $black;

		b {
			font-size: 14px;
			font-weight: 900;
		}
	}
}

.accing-wrapper {
	@media (min-width: $breakpoint-sm) {
		padding-left: 20px;
	}
}

.accing-title {
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: $grey;
	padding-left: 10px;
}

.accing-field-list {
	padding-bottom: 20px;
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	gap: 30px;
	padding-left: 25px;

	@media (min-width: $breakpoint-sm) {
		display: flex;
		align-items: flex-start;
		gap: 10px 3.5vw;
		padding-left: 9px;
	}
}

.accing-field-item {
	position: relative;
	min-width: 18vw;

	.input-form-grid {
		display: flex;
		align-items: center;
		flex-direction: row;
		gap: 10px;

		.label-grid {
			padding: 0;
			margin: 0;
		}

		.grid-header {
			width: auto;
		}

		.suplabel-grid {
			display: none;
		}
	}
}

.accing-field-info {
	font-size: 10px;
	font-weight: normal;
	color: $grey;
}

.accing-cols {
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	gap: 14px;
	padding-left: 25px;

	@media (min-width: $breakpoint-sm) {
		display: flex;
		flex-wrap: wrap;
		gap: 10px 3.5vw;
		padding-left: 9px;
	}
}

.accing-btn {
	width: 100% !important;
	margin-right: 0 !important;
	margin-left: auto !important;
	padding: 0 5% !important;

	@media (min-width: $breakpoint-sm) {
		width: auto !important;
	}
}

.accing-footer-btn {
	width: 100% !important;
	margin: 0 auto !important;
	margin-top: 20px !important;
	padding: 0 5% !important;

	@media (min-width: $breakpoint-sm) {
		width: auto !important;
	}
}

.accing-toggle-section {
	padding-bottom: 18px;
	border-bottom: 1px solid $silver;
	margin-bottom: 2px;

	&:not(:first-child) {
		padding-top: 10px;
	}

	@media (min-width: $breakpoint-sm) {
		padding-bottom: 12px;
	}
}

.accing-toggle-header {
	display: grid;
	grid-template-columns: 1fr;
	gap: 10px;
	padding-top: 10px;
	position: relative;

	@media (min-width: $breakpoint-sm) {
		display: flex;
		align-items: center;
		padding-left: 4px;
		margin-bottom: 0;
	}

	.suplabel-grid {
		display: none;
	}

	.input-form-grid {
		min-width: 300px;
	}
}

.accing-modal {
	padding: 0 18px;
}

.accing-modal-form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
	padding: 20px 12px 36px 12px;
	margin: 0 auto;

	.label,
	.label-grid {
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 900;
		color: $black;
		padding-left: 3px;
		margin-bottom: 2px;
	}

	.accing-modal-input {
		grid-column: span 2;
	}
}

.accing-invite-modal {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 12px 10px;
	padding: 10px 28px 25px 28px;
	margin: 0 auto;

	.label,
	.label-grid {
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 900;
		color: $black;
		padding-left: 0;
		margin-bottom: 0;
	}

	.input-form-grid {
		gap: 10px;
	}

	.accing-modal-input {
		grid-column: span 2;
	}
}

.btn-accing-invite {
	width: 42% !important;
	min-width: fit-content !important;
}

.accing-modal-btns {
	display: grid;
	grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 0.5fr);
	align-items: center;
	gap: 20px;
}

.accing-plan-btns {
	display: grid;
	grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 0.65fr);
	align-items: center;
	gap: 15px;
	width: 73%;
	margin: 0 auto;
	margin-top: 20px;
}

.accing-modal-title {
	display: block;
	font-family: 'avenir', sans-serif;
	font-size: 16px;
	font-weight: 900;
	color: $black;
	margin: 10px 0;
	text-align: center;
}

.input-form-accing-modal {
	.suplabel-grid {
		display: none;
	}

	.input-grid,
	.grid-error {
		width: 82%;
		margin: 0 auto;
	}
}

.accing-w9-modal {
	width: 100% !important;
}

.accing-info-btns {
	display: grid;
	grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 132px);
	align-items: center;
	gap: 15px;
	width: 50%;
	margin: 0 auto;
	margin-top: 20px;
}
