.sidebar {
	position: absolute;
	height: 100vh;
	overflow: auto;
	opacity: 0;
	max-width: 0;
	background-color: $black;
	transition: max-width 0.1s;
	flex: none;
	z-index: 3;

	&.is-open {
		opacity: 1;
		width: 85vw;
		max-width: 85vw;
		padding: 15px 12px;
		transition: max-width 0.1s;

		@media (min-width: 768px) {
			padding: 43px 35px;
			max-width: 280px;
			width: 280px;
		}

		@media (min-width: 1200px) {
			width: 300px;
			max-width: 300px;
		}
	}

	@media (min-width: 768px) {
		position: relative;
		height: 100%;
		z-index: auto;
	}
}

.sidebar-mob-back {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100%;
	background-color: rgba(235, 235, 235, 0.8);
	z-index: 2;

	@media (min-width: 768px) {
		display: none;
	}
}

.sidebar-close {
	@extend .arrow-line-white;
	position: absolute;
	top: 14px;
	right: 23px;
	padding: 10px;
	border: 0;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	transition: all 0.3s;

	.nav-item & {
		top: 0;
		transform: rotate(-90deg);

		.is-open > & {
			transform: rotate(-270deg);
		}
	}

	@media (min-width: 768px) {
		right: 15px;
		top: 43px;
	}
}
