.card {
	display: flex;
	flex-direction: column;
	background-color: $white;
	padding: 8px 4px 10px;
	border-radius: 2px;
}

.card-chart {
	padding-top: 25px;
}

.card-header-thumb {
	overflow: hidden;
	display: flex;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid $silver;
	gap: 5px;
	margin-bottom: 9px;
	padding-bottom: 8px;

	.col-2 & {
		min-width: fit-content;
		width: 50%;
	}
}

.card-subtitle {
	font-size: 12px;
	font-weight: 350;
	letter-spacing: 0.75px;
	color: $grey;
}

.card-body {
	flex: 1 1 auto;
	border: dashed 1px $silver;
	overflow-x: auto;

	&.card-table {
		overflow: auto;
	}
}

.modal-table-container {
	border: 1px dashed $black;
	overflow: auto;
	margin-bottom: 20px;
}

.card-table,
.org-table,
.price-table {
	width: 100%;
	border-collapse: collapse;

	table {
		overflow: auto;
		height: auto;
		max-height: 100%;
		border-collapse: collapse;
		background-color: #fff;
		width: 100%;
	}

	tr {
		display: block;
		width: 100%;
	}

	thead {
		display: block;
		position: sticky;
		top: 0;
		left: 0;
		background-color: #fff;
		z-index: 1;

		th {
			font-size: 16px;
			font-weight: 900;
			font-family: 'avenir', sans-serif;
			text-align: center;
			color: $black;
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			border-bottom: 1px solid #000;

			.card & {
				font-size: 14px;
			}

			.tr-arrow {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&:first-child {
				text-align: left;

				.tr-arrow {
					justify-content: flex-start;
				}
			}
		}
	}

	tbody {
		display: block;
		height: auto;

		td {
			font-family: 'avenir', sans-serif;
			font-size: 14px;
			font-weight: 350;
			color: $black;
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			text-align: center;

			.card & {
				font-size: 12px;
				font-weight: 350;
				color: $grey;
			}

			&:first-child {
				text-align: left;
			}
		}

		tr {
			width: 100%;
			font-family: 'avenir', sans-serif;
			font-size: 14px;
			font-weight: 350;
			color: $black;

			&:nth-of-type(odd) {
				background-color: #f7f7f7;
			}

			&:not(:last-of-type) > .td {
				border-bottom: 0;
			}
		}
	}

	th,
	td {
		align-items: baseline;
		align-self: baseline;
		padding: 4px 10px;
	}

	div,
	.tr-right {
		flex: 1;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.tr-right {
		align-items: flex-end;
		text-align: right;
	}

	.td-right {
		align-items: flex-end;
		text-align: right;
	}

	.tr-left {
		align-items: flex-start;
		text-align: left;
	}

	.td-left {
		align-items: flex-start;
		text-align: left;
	}
}

.card-table-link {
	display: block;
	align-items: center;
	font-size: 14px;
	font-weight: 900;
	font-style: normal;
	color: $blue;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;

	.card & {
		font-size: 12px;
	}
}

.card-title {
	font-size: 20px;
	font-weight: 900;
	color: $black;
}

.card-link-icon {
	display: flex;
	align-items: center;
	max-width: 50vw;

	@media (min-width: 768px) {
		max-width: unset;
	}
}

.card-table-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 31px;
	min-width: 31px;
	height: 31px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 10px;
}

.org-table-image {
	thead {
		th {
			&:first-child,
			&:last-child {
				width: 40px !important;
				min-width: 40px !important;
			}

			&:nth-child(2) {
				width: 22% !important;
				min-width: 22% !important;
			}

			&:nth-last-child(2) {
				width: 100% !important;
				max-width: calc(60% - 80px) !important;
			}

			div {
				overflow: hidden;
			}
		}
	}

	tbody {
		td {
			&:first-child,
			&:last-child {
				width: 40px !important;
				min-width: 40px !important;
			}

			&:nth-child(2) {
				width: 22% !important;
				min-width: 22% !important;
			}

			&:nth-last-child(2) {
				width: 100% !important;
				max-width: calc(60% - 80px) !important;
			}

			div {
				overflow: hidden;
			}
		}
	}
}

.card-orders-table {
	th,
	td {
		&:first-child {
			width: 100% !important;
			max-width: 10% !important;
		}

		&:nth-child(2) {
			width: 100% !important;
			max-width: 26% !important;
		}

		&:nth-last-child(2) {
			width: 100% !important;
			max-width: 19% !important;
		}
	}
}
