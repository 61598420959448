*,
*:before,
*:after {
	font-family: 'avenir', sans-serif;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html,
body {
	min-height: 100%;
}

body {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 2500px;
	font-family: 'avenir', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 14px;
	line-height: 1.14;
	color: $black;
}

ul {
	list-style: none;
}

*,
*:active,
*:focus {
	outline: none;
}

a {
	text-decoration: none;
	color: inherit;
}

.paragraph {
	margin-bottom: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

textarea {
	resize: none;
}
