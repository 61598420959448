.loader-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-width: 100px;
	height: 200px;

	&.qr {
		height: 100px;

		@media (min-width: 768px) {
			width: 7vw;
			height: 7vw;
		}
	}

	@media (min-width: 768px) {
		width: 310px;
	}
}

.loader {
	width: 35%;
	max-width: 50px;
	padding: 5px;
	aspect-ratio: 1;
	border-radius: 50%;
	background: #f15635;
	--_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
	-webkit-mask: var(--_m);
	mask: var(--_m);
	-webkit-mask-composite: source-out;
	mask-composite: subtract;
	animation: l3 1s infinite linear;
}

@keyframes l3 {
	to {
		transform: rotate(1turn);
	}
}

.page-loader-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	&.is-page {
		height: 78vh;
	}

	&.is-section {
		height: 38vh;
	}

	&.is-content {
		height: auto;
		padding: 20px 0;
	}
}

.loader-sm {
	width: 25px;
	max-width: 25px;
	padding: 3px;
}
