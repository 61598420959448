.content {
	height: 100%;
	flex: 1 1;
	padding: 45px 8px 0 8px;
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-x: hidden;
}

.content-thumb {
	overflow-x: auto;
	display: flex;
}
