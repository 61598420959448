.sm-notif {
  height: 100%;
  min-height: calc(100vh - 172px);
  background-color: $ivory;
  display: flex;
  flex-direction: column;
}

.sm-notif-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 30px;
}

.sm-notif-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 60px;
  min-width: 60px;
}

.sm-notif-title {
  display: block;
  font-size: 20px;
  font-weight: 900;
  color: $black;
  text-align: justify;
  margin-top: 12px;
  margin-bottom: 45px;
  line-height: 1.3;
}

.sm-notif-text {
  display: block;
  font-size: 12px;
  font-weight: 350;
  color: $grey;
  text-align: center;
  line-height: 1.3;
  margin-bottom: 10px;
}

.sm-notif-btn {
}

.sm-notif-contacts {
  display: flex;
  align-items: flex-end;
  font-size: 16px;
  font-weight: 900;
  color: $black;
  margin-top: 30px;
  gap: 5px;
}

.sm-notif-link {
  display: block;
  font-size: 14px;
  font-weight: 900;
  color: $blue;
  cursor: pointer;
}

.sm-notif-user {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13px;
  min-width: 13px;
  height: 100%;
}
