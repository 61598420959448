.notif-modal {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #f15635;
  width: 300px;
  padding: 8px 5px 8px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.notif-title {
  font-family: "avenir", sans-serif;
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  display: block;
  width: 100%;
  color: #2c2e45;
}

.notif-info {
  font-family: "avenir", sans-serif;
  font-size: 14px;
  font-weight: 350;
  color: #2c2e45;
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 1px;
}

.notif-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  min-width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #f7f7f7;
  font-size: 20px;
  font-weight: 350;
  color: #2c2e45;
  cursor: pointer;
  box-shadow: 1px 2px 2px 0px #00000040;
  border: none;

  &:focus {
    outline: none;
  }
}
