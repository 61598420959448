.switch {
	display: flex;
	align-items: center;
	margin-bottom: 20px;

	@media (min-width: 768px) {
		margin-top: 7px;
		margin-bottom: 5px;
	}
}

.switch-input {
	height: 0;
	width: 0;
	visibility: hidden;
	display: none;
}

.switch-suplabel {
	display: flex;
	align-items: center;
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: $grey;
	margin-left: 5px;
}

.switch-label {
	cursor: pointer;
	width: 28px;
	height: 10px;
	background-color: $grey;
	display: block;
	border-radius: 14px;
	position: relative;
	margin-right: 25px;

	&::after {
		content: '';
		position: absolute;
		top: -3px;
		left: -3px;
		width: 16px;
		height: 16px;
		background-color: $ivory;
		border: 2px solid $grey;
		border-radius: 90px;
		transition: 0.3s;
	}
}

.switch-input:checked + .switch-label {
	background-color: #f15635;
}

.switch-input:checked + .switch-label:after {
	left: calc(100% + 2px);
	transform: translateX(-100%);
}

.switch-label:active:after {
	width: 20px;
}

.switch-input:disabled + .switch-label {
	background-color: $grey-light;
	cursor: default;
}

.switch-input:disabled + .switch-label:after {
	border-color: $grey-light;
	cursor: default;
}

.switch-text {
	display: flex;
	align-items: flex-end;
	font-size: 14px;
	font-weight: 350;
	color: $black;

	@media (min-width: 768px) {
		font-size: 12px;
		font-weight: normal;
	}
}
