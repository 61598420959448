.aside {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  opacity: 0;
  max-width: 0;
  background-color: $white;
  transition: max-width 0.1s;
  height: 100%;
  overflow: auto;
  flex: none;
  z-index: 1;

  &.is-open {
    opacity: 1;
    width: 100vw;
    max-width: 100vw;
    padding: 58px 8px 20px 50px;
    transition: max-width 0.1s;

    @media (min-width: 768px) {
      padding: 43px 30px 16px 16px;
      max-width: 280px;
      width: 280px;
    }

    @media (min-width: 1200px) {
      width: 300px;
      max-width: 300px;
    }
  }

  @media (min-width: 768px) {
    position: relative;
    z-index: auto;
  }
}

.asside-collapse {
  @extend .arrow-line;
  font-family: "avenir", sans-serif;
  font-weight: normal;
  font-size: 14px;
  background-position: left center;
  background-repeat: no-repeat;
  color: $grey-dark;
  border: none;
  background-color: transparent;
  padding-left: 20px;
  letter-spacing: -1px;
  margin-left: -18px;
  cursor: pointer;

  @media (min-width: 768px) {
    line-height: 1.6;
    padding-left: 10px;
    margin-left: 0;
    letter-spacing: normal;
  }
}

.aside-section {
  border-top: 1px solid $silver;
  margin-top: 15px;
  padding-top: 13px;

  @media (min-width: 768px) {
    margin-top: 10px;
    padding-top: 12px;
  }
}

.aside-section-title {
  display: none;
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 14px;
  letter-spacing: 0;

  @media (min-width: 768px) {
    display: block;
  }
}

.aside-menu {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 10px;

  @media (min-width: 768px) {
    gap: 12px;
    padding-left: 20px;
  }
}

.aside-menu-link {
  display: inline-block;
  text-align: left;
  width: auto;
  font-family: "avenir", sans-serif;
  line-height: 14.4px;
  font-size: 14px;
  font-weight: 350;
  letter-spacing: 0;
  padding: 2px 0;
  padding-left: 10px;
  color: #2b2e45;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  @media (min-width: 768px) {
    padding-left: 0;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
  }

  &.bold {
    font-weight: 900;
    letter-spacing: 0;
  }
}

.aside-content {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 11px;
}

.aside-mob-section {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}
