.datepicker {
	border: 0.5px solid #9e9faf;
	background-color: #fff;
	font-size: 12px;
	font-weight: 350;
	color: #2c2e45;
	border-radius: 2px;
	padding: 5px;
	height: 25px;
	max-height: 25px;
	width: 100%;
	min-width: 100px;

	&::placeholder {
		font-size: 12px;
		font-weight: 350;
		color: #c7c7c7;
	}

	&:focus {
		outline: 3px solid #fec52e;
		border-radius: 2px;
		border-color: transparent;
	}

	&:read-only {
		border-color: #9e9faf;
		background-color: #e2e2e2;
		outline: none;
		cursor: default;

		&::placeholder {
			color: #9e9faf;
		}
	}

	&:disabled {
		background-color: #fff;
		border-color: #e2e2e2;

		&::placeholder {
			color: #e2e2e2;
		}
	}
}

.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker-popper {
	z-index: 2;
}

.datepicker-error {
	display: flex;
	align-items: center;
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	color: #f15635;
}
