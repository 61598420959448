.select-custom,
.select__menu-portal {
	.select__control {
		border: 0.5px solid #9e9faf;
		background-color: #fff !important ;
		font-size: 12px;
		font-weight: 350;
		color: #2c2e45;
		border-radius: 2px !important;
		padding: 5px;
		height: 25px;
		min-height: unset !important;
		width: 100%;
		cursor: pointer;

		&.select__control--menu-is-open {
			.select__dropdown-indicator::before {
				transform: rotate(180deg);
			}
		}

		&--is-focused {
			outline: 3px solid #fec52e !important;
			border-radius: 2px !important;
			border-color: transparent !important;

			&:hover {
				border-color: transparent !important;
			}
		}
	}

	&.readonly {
		.select__control {
			border-color: #9e9faf !important;
			background-color: #e2e2e2 !important;
		}

		.select__placeholder {
			color: #9e9faf !important;
		}

		.select__dropdown-indicator {
			&::before {
				background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_62_1877)'%3E%3Cpath d='M1.12989 1.32376L5.49989 5.90576M9.83789 1.32276L5.46789 5.90576' stroke='%239E9FAF' stroke-width='1.1' stroke-linecap='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_62_1877'%3E%3Crect width='11' height='7' fill='white' transform='translate(11 7) rotate(-180)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") !important;
			}
		}
	}

	.select__value-container {
		max-width: calc(100% - 10px);
	}

	.select__value-container,
	.select__placeholder,
	.select__input-container,
	.select__input,
	.select__indicators,
	.select__indicator,
	.select__dropdown-indicator {
		height: 100%;
		min-height: unset !important;
		padding: 0 !important;
		margin: 0 !important;
		width: auto !important;
		flex: unset !important;
	}

	.select__indicator-separator {
		display: none;
	}

	.select__placeholder {
		font-size: 12px;
		font-weight: 350;
		color: #c7c7c7 !important;
	}

	.select__dropdown-indicator {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		width: 20px;
		height: 20px;

		@media (min-width: 768px) {
			width: 10px;
			height: 10px;
		}

		&::before {
			content: '';
			display: block;
			width: 20px;
			height: 20px;
			background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_46_319)'%3E%3Cpath d='M1.13 1.324L5.5 5.90601M9.838 1.32301L5.468 5.90601' stroke='%232C2E45' stroke-width='1.1' stroke-linecap='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_46_319'%3E%3Crect width='11' height='7' fill='white' transform='translate(11 7) rotate(-180)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			background-position: center;
			cursor: pointer;

			@media (min-width: 768px) {
				width: 10px;
				height: 10px;
			}
		}

		svg {
			display: none;
		}
	}

	.select__menu {
		box-shadow: unset;
		border-radius: 2px;
		z-index: 9;

		.modal > & {
			max-height: 80px;
			overflow-y: auto;
		}
	}

	.select__menu-list {
		padding: 0;
		border: 0.5px solid #9e9faf;
		border-radius: 2px;
	}

	.select__option {
		font-family: 'avenir', sans-serif;
		font-size: 14px;
		font-weight: 350;
		color: #000000;
		background-color: #fff !important;
		line-height: 1.7;
		cursor: pointer;

		&--is-focused {
			font-weight: 350;
		}

		&:hover {
			font-weight: 900;
		}

		&--is-selected {
			font-weight: 900;
			background-color: #fff;
			text-decoration: underline;
			text-decoration-thickness: 3px;
			text-decoration-color: #fec52e;
			text-decoration-skip-ink: 'none';
			text-underline-offset: 5px;
		}
	}
}

.select--is-disabled {
	.select__control {
		border-color: #e2e2e2;
	}

	.select__placeholder {
		color: #e2e2e2;
	}

	.select__dropdown-indicator {
		&::before {
			background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_65_2938)'%3E%3Cpath d='M1.13 1.324L5.5 5.90601M9.838 1.32301L5.468 5.90601' stroke='%23E2E2E2' stroke-width='1.1' stroke-linecap='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_65_2938'%3E%3Crect width='11' height='7' fill='white' transform='translate(11 7) rotate(-180)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
		}
	}
}

.select-column {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.select-error-container {
	gap: 0;
}
