.card-content-info {
	padding: 18px 18px 5px;
}

.card-info-item {
	display: flex;
	align-items: flex-start;
	gap: 10px;
	&:not(:last-child) {
		margin-bottom: 16px;
	}
}

.card-info-icon {
	flex: none;
	width: 40px;
	height: 40px;
	background-repeat: no-repeat;
	background-position: center;
}

.card-info-content {
	display: flex;
	flex-direction: column;
	font-size: 11px;
	font-weight: normal;
	color: $grey;
	line-height: 1.55;
}

.card-info-title {
	@extend .title-3;
	color: $blue;
	font-weight: 900;
	line-height: 1;
	margin-bottom: 5px;
}
