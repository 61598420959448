@font-face {
	font-family: 'my-riad-pro', sans-serif;
	src: url('../fonts/Avenir.ttc');
	src: url('../fonts/Avenir.ttc') format('ttc');

	font-weight: normal;
	font-style: normal;
	-webkit-text-stroke-width: 0.2px;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

@font-face {
	font-family: 'avenir', sans-serif;
	src: url('../fonts/Avenir/AvenirLT-Roman.eot');
	src: local('Avenir LT 55 Roman'), local('AvenirLT-Roman'),
		url('../fonts/Avenir/AvenirLT-Roman.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir/AvenirLT-Roman.woff') format('woff'),
		url('../fonts/Avenir/AvenirLT-Roman.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'avenir', sans-serif;
	src: url('../fonts/Avenir/AvenirLT-MediumOblique.eot');
	src: local('Avenir LT 65 Medium Oblique'), local('AvenirLT-MediumOblique'),
		url('../fonts/Avenir/AvenirLT-MediumOblique.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/Avenir/AvenirLT-MediumOblique.woff') format('woff'),
		url('../fonts/Avenir/AvenirLT-MediumOblique.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'avenir', sans-serif;
	src: url('../fonts/Avenir/AvenirLT-Heavy.eot');
	src: local('Avenir LT 85 Heavy'), local('AvenirLT-Heavy'),
		url('../fonts/Avenir/AvenirLT-Heavy.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir/AvenirLT-Heavy.woff') format('woff'),
		url('../fonts/Avenir/AvenirLT-Heavy.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'avenir', sans-serif;
	src: url('../fonts/Avenir/AvenirLT-Black.eot');
	src: local('Avenir LT 95 Black'), local('AvenirLT-Black'),
		url('../fonts/Avenir/AvenirLT-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir/AvenirLT-Black.woff') format('woff'),
		url('../fonts/Avenir/AvenirLT-Black.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'avenir', sans-serif;
	src: url('../fonts/Avenir/AvenirLT-LightOblique.eot');
	src: local('Avenir LT 35 Light Oblique'), local('AvenirLT-LightOblique'),
		url('../fonts/Avenir/AvenirLT-LightOblique.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/Avenir/AvenirLT-LightOblique.woff') format('woff'),
		url('../fonts/Avenir/AvenirLT-LightOblique.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'avenir', sans-serif;
	src: url('../fonts/Avenir/AvenirLT-BlackOblique.eot');
	src: local('Avenir LT 95 Black Oblique'), local('AvenirLT-BlackOblique'),
		url('../fonts/Avenir/AvenirLT-BlackOblique.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/Avenir/AvenirLT-BlackOblique.woff') format('woff'),
		url('../fonts/Avenir/AvenirLT-BlackOblique.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'avenir', sans-serif;
	src: url('../fonts/Avenir/AvenirLT-HeavyOblique.eot');
	src: local('Avenir LT 86 Heavy Oblique'), local('AvenirLT-HeavyOblique'),
		url('../fonts/Avenir/AvenirLT-HeavyOblique.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/Avenir/AvenirLT-HeavyOblique.woff') format('woff'),
		url('../fonts/Avenir/AvenirLT-HeavyOblique.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'avenir', sans-serif;
	src: url('../fonts/Avenir/AvenirLT-Medium.eot');
	src: local('Avenir LT 65 Medium'), local('AvenirLT-Medium'),
		url('../fonts/Avenir/AvenirLT-Medium.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/Avenir/AvenirLT-Medium.woff') format('woff'),
		url('../fonts/Avenir/AvenirLT-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'avenir', sans-serif;
	src: url('../fonts/Avenir/AvenirLT-Light.eot');
	src: local('Avenir LT 35 Light'), local('AvenirLT-Light'),
		url('../fonts/Avenir/AvenirLT-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir/AvenirLT-Light.woff') format('woff'),
		url('../fonts/Avenir/AvenirLT-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'avenir', sans-serif;
	src: url('../fonts/Avenir/AvenirLT-Book.eot');
	src: local('Avenir LT 45 Book'), local('AvenirLT-Book'),
		url('../fonts/Avenir/AvenirLT-Book.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Avenir/AvenirLT-Book.woff') format('woff'),
		url('../fonts/Avenir/AvenirLT-Book.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'avenir', sans-serif;
	src: url('../fonts/Avenir/AvenirLT-Oblique.eot');
	src: local('Avenir LT 55 Oblique'), local('AvenirLT-Oblique'),
		url('../fonts/Avenir/AvenirLT-Oblique.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/Avenir/AvenirLT-Oblique.woff') format('woff'),
		url('../fonts/Avenir/AvenirLT-Oblique.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'avenir', sans-serif;
	src: url('../fonts/Avenir/AvenirLT-BookOblique.eot');
	src: local('Avenir LT 45 Book Oblique'), local('AvenirLT-BookOblique'),
		url('../fonts/Avenir/AvenirLT-BookOblique.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/Avenir/AvenirLT-BookOblique.woff') format('woff'),
		url('../fonts/Avenir/AvenirLT-BookOblique.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

// Myriad

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-BoldCondIt.eot');
	src: local('Myriad Pro Bold Condensed Italic'), local('MyriadPro-BoldCondIt'),
		url('../fonts/MyriadPro/MyriadPro-BoldCondIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-BoldCondIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-BoldCondIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-BlackIt.eot');
	src: local('Myriad Pro Black Italic'), local('MyriadPro-BlackIt'),
		url('../fonts/MyriadPro/MyriadPro-BlackIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-BlackIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-BlackIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-LightIt.eot');
	src: local('Myriad Pro Light Italic'), local('MyriadPro-LightIt'),
		url('../fonts/MyriadPro/MyriadPro-LightIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-LightIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-BlackCond.eot');
	src: local('Myriad Pro Black Condensed'), local('MyriadPro-BlackCond'),
		url('../fonts/MyriadPro/MyriadPro-BlackCond.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-BlackCond.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-BlackCond.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-Cond.eot');
	src: local('Myriad Pro Condensed'), local('MyriadPro-Cond'),
		url('../fonts/MyriadPro/MyriadPro-Cond.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-Cond.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-Cond.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-Bold.eot');
	src: local('Myriad Pro Bold'), local('MyriadPro-Bold'),
		url('../fonts/MyriadPro/MyriadPro-Bold.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-Bold.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-BoldIt.eot');
	src: local('Myriad Pro Bold Italic'), local('MyriadPro-BoldIt'),
		url('../fonts/MyriadPro/MyriadPro-BoldIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-BoldIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-BoldIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-BoldSemiCnIt.eot');
	src: local('Myriad Pro Bold SemiCondensed Italic'),
		local('MyriadPro-BoldSemiCnIt'),
		url('../fonts/MyriadPro/MyriadPro-BoldSemiCnIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-BoldSemiCnIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-BoldSemiCnIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-CondIt.eot');
	src: local('Myriad Pro Condensed Italic'), local('MyriadPro-CondIt'),
		url('../fonts/MyriadPro/MyriadPro-CondIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-CondIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-CondIt.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-LightCond.eot');
	src: local('Myriad Pro Light Condensed'), local('MyriadPro-LightCond'),
		url('../fonts/MyriadPro/MyriadPro-LightCond.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-LightCond.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-LightCond.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-SemiCn.eot');
	src: local('Myriad Pro SemiCondensed'), local('MyriadPro-SemiCn'),
		url('../fonts/MyriadPro/MyriadPro-SemiCn.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-SemiCn.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-SemiCn.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-SemiCnIt.eot');
	src: local('Myriad Pro SemiCondensed Italic'), local('MyriadPro-SemiCnIt'),
		url('../fonts/MyriadPro/MyriadPro-SemiCnIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-SemiCnIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-SemiCnIt.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-BoldCond.eot');
	src: local('Myriad Pro Bold Condensed'), local('MyriadPro-BoldCond'),
		url('../fonts/MyriadPro/MyriadPro-BoldCond.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-BoldCond.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-BoldCond.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-LightSemiExtIt.eot');
	src: local('Myriad Pro Light SemiExtended Italic'),
		local('MyriadPro-LightSemiExtIt'),
		url('../fonts/MyriadPro/MyriadPro-LightSemiExtIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-LightSemiExtIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-LightSemiExtIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-Semibold.eot');
	src: local('Myriad Pro Semibold'), local('MyriadPro-Semibold'),
		url('../fonts/MyriadPro/MyriadPro-Semibold.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-Semibold.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-Black.eot');
	src: local('Myriad Pro Black'), local('MyriadPro-Black'),
		url('../fonts/MyriadPro/MyriadPro-Black.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-Black.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-Regular.eot');
	src: local('Myriad Pro Regular'), local('MyriadPro-Regular'),
		url('../fonts/MyriadPro/MyriadPro-Regular.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-Regular.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-LightSemiCn.eot');
	src: local('Myriad Pro Light SemiCondensed'), local('MyriadPro-LightSemiCn'),
		url('../fonts/MyriadPro/MyriadPro-LightSemiCn.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-LightSemiCn.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-LightSemiCn.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-BlackCondIt.eot');
	src: local('Myriad Pro Black Condensed Italic'),
		local('MyriadPro-BlackCondIt'),
		url('../fonts/MyriadPro/MyriadPro-BlackCondIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-BlackCondIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-BlackCondIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-LightCondIt.eot');
	src: local('Myriad Pro Light Condensed Italic'),
		local('MyriadPro-LightCondIt'),
		url('../fonts/MyriadPro/MyriadPro-LightCondIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-LightCondIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-LightCondIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-LightSemiExt.eot');
	src: local('Myriad Pro Light SemiExtended'), local('MyriadPro-LightSemiExt'),
		url('../fonts/MyriadPro/MyriadPro-LightSemiExt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-LightSemiExt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-LightSemiExt.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-BlackSemiCnIt.eot');
	src: local('Myriad Pro Black SemiCondensed Italic'),
		local('MyriadPro-BlackSemiCnIt'),
		url('../fonts/MyriadPro/MyriadPro-BlackSemiCnIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-BlackSemiCnIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-BlackSemiCnIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-SemiboldCondIt.eot');
	src: local('Myriad Pro Semibold Condensed Italic'),
		local('MyriadPro-SemiboldCondIt'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldCondIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldCondIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldCondIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-SemiboldCond.eot');
	src: local('Myriad Pro Semibold Condensed'), local('MyriadPro-SemiboldCond'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldCond.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldCond.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldCond.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-BoldSemiExtIt.eot');
	src: local('Myriad Pro Bold SemiExtended Italic'),
		local('MyriadPro-BoldSemiExtIt'),
		url('../fonts/MyriadPro/MyriadPro-BoldSemiExtIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-BoldSemiExtIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-BoldSemiExtIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-SemiboldSemiCn.eot');
	src: local('Myriad Pro Semibold SemiCondensed'),
		local('MyriadPro-SemiboldSemiCn'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldSemiCn.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldSemiCn.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldSemiCn.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-BlackSemiExtIt.eot');
	src: local('Myriad Pro Black SemiExtended Italic'),
		local('MyriadPro-BlackSemiExtIt'),
		url('../fonts/MyriadPro/MyriadPro-BlackSemiExtIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-BlackSemiExtIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-BlackSemiExtIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-SemiboldSemiExtIt.eot');
	src: local('Myriad Pro Semibold SemiExtended Italic'),
		local('MyriadPro-SemiboldSemiExtIt'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldSemiExtIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldSemiExtIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldSemiExtIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-LightSemiCnIt.eot');
	src: local('Myriad Pro Light SemiCondensed Italic'),
		local('MyriadPro-LightSemiCnIt'),
		url('../fonts/MyriadPro/MyriadPro-LightSemiCnIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-LightSemiCnIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-LightSemiCnIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-BoldSemiCn.eot');
	src: local('Myriad Pro Bold SemiCondensed'), local('MyriadPro-BoldSemiCn'),
		url('../fonts/MyriadPro/MyriadPro-BoldSemiCn.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-BoldSemiCn.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-BoldSemiCn.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-SemiboldSemiCnIt.eot');
	src: local('Myriad Pro Semibold SemiCondensed Italic'),
		local('MyriadPro-SemiboldSemiCnIt'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldSemiCnIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldSemiCnIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldSemiCnIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-SemiboldIt.eot');
	src: local('Myriad Pro Semibold Italic'), local('MyriadPro-SemiboldIt'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-It.eot');
	src: local('Myriad Pro Italic'), local('MyriadPro-It'),
		url('../fonts/MyriadPro/MyriadPro-It.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-It.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-It.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-BoldSemiExt.eot');
	src: local('Myriad Pro Bold SemiExtended'), local('MyriadPro-BoldSemiExt'),
		url('../fonts/MyriadPro/MyriadPro-BoldSemiExt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-BoldSemiExt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-BoldSemiExt.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-Light.eot');
	src: local('Myriad Pro Light'), local('MyriadPro-Light'),
		url('../fonts/MyriadPro/MyriadPro-Light.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-Light.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-SemiboldSemiExt.eot');
	src: local('Myriad Pro Semibold SemiExtended'),
		local('MyriadPro-SemiboldSemiExt'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldSemiExt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldSemiExt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-SemiboldSemiExt.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-SemiExtIt.eot');
	src: local('Myriad Pro SemiExtended Italic'), local('MyriadPro-SemiExtIt'),
		url('../fonts/MyriadPro/MyriadPro-SemiExtIt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-SemiExtIt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-SemiExtIt.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-BlackSemiCn.eot');
	src: local('Myriad Pro Black SemiCondensed'), local('MyriadPro-BlackSemiCn'),
		url('../fonts/MyriadPro/MyriadPro-BlackSemiCn.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-BlackSemiCn.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-BlackSemiCn.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-BlackSemiExt.eot');
	src: local('Myriad Pro Black SemiExtended'), local('MyriadPro-BlackSemiExt'),
		url('../fonts/MyriadPro/MyriadPro-BlackSemiExt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-BlackSemiExt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-BlackSemiExt.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad', sans-serif;
	src: url('../fonts/MyriadPro/MyriadPro-SemiExt.eot');
	src: local('Myriad Pro SemiExtended'), local('MyriadPro-SemiExt'),
		url('../fonts/MyriadPro/MyriadPro-SemiExt.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/MyriadPro/MyriadPro-SemiExt.woff') format('woff'),
		url('../fonts/MyriadPro/MyriadPro-SemiExt.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

// Nunito

@font-face {
	font-family: 'Nunito', sans-serif;
	src: url('../fonts/NunitoSans/NunitoSans-Regular.eot');
	src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
		url('../fonts/NunitoSans/NunitoSans-Regular.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/NunitoSans/NunitoSans-Regular.woff') format('woff'),
		url('../fonts/NunitoSans/NunitoSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito', sans-serif;
	src: url('../fonts/NunitoSans/NunitoSans-Light.eot');
	src: local('Nunito Sans Light'), local('NunitoSans-Light'),
		url('../fonts/NunitoSans/NunitoSans-Light.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/NunitoSans/NunitoSans-Light.woff') format('woff'),
		url('../fonts/NunitoSans/NunitoSans-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito', sans-serif;
	src: url('../fonts/NunitoSans/NunitoSans-LightItalic.eot');
	src: local('Nunito Sans Light Italic'), local('NunitoSans-LightItalic'),
		url('../fonts/NunitoSans/NunitoSans-LightItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/NunitoSans/NunitoSans-LightItalic.woff') format('woff'),
		url('../fonts/NunitoSans/NunitoSans-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito', sans-serif;
	src: url('../fonts/NunitoSans/NunitoSans-BlackItalic.eot');
	src: local('Nunito Sans Black Italic'), local('NunitoSans-BlackItalic'),
		url('../fonts/NunitoSans/NunitoSans-BlackItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/NunitoSans/NunitoSans-BlackItalic.woff') format('woff'),
		url('../fonts/NunitoSans/NunitoSans-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito', sans-serif;
	src: url('../fonts/NunitoSans/NunitoSans-SemiBold.eot');
	src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'),
		url('../fonts/NunitoSans/NunitoSans-SemiBold.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/NunitoSans/NunitoSans-SemiBold.woff') format('woff'),
		url('../fonts/NunitoSans/NunitoSans-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito', sans-serif;
	src: url('../fonts/NunitoSans/NunitoSans-Bold.eot');
	src: local('Nunito Sans Bold'), local('NunitoSans-Bold'),
		url('../fonts/NunitoSans/NunitoSans-Bold.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/NunitoSans/NunitoSans-Bold.woff') format('woff'),
		url('../fonts/NunitoSans/NunitoSans-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito', sans-serif;
	src: url('../fonts/NunitoSans/NunitoSans-ExtraBold.eot');
	src: local('Nunito Sans ExtraBold'), local('NunitoSans-ExtraBold'),
		url('../fonts/NunitoSans/NunitoSans-ExtraBold.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/NunitoSans/NunitoSans-ExtraBold.woff') format('woff'),
		url('../fonts/NunitoSans/NunitoSans-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito', sans-serif;
	src: url('../fonts/NunitoSans/NunitoSans-SemiBoldItalic.eot');
	src: local('Nunito Sans SemiBold Italic'), local('NunitoSans-SemiBoldItalic'),
		url('../fonts/NunitoSans/NunitoSans-SemiBoldItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/NunitoSans/NunitoSans-SemiBoldItalic.woff') format('woff'),
		url('../fonts/NunitoSans/NunitoSans-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito', sans-serif;
	src: url('../fonts/NunitoSans/NunitoSans-Black.eot');
	src: local('Nunito Sans Black'), local('NunitoSans-Black'),
		url('../fonts/NunitoSans/NunitoSans-Black.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/NunitoSans/NunitoSans-Black.woff') format('woff'),
		url('../fonts/NunitoSans/NunitoSans-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito', sans-serif;
	src: url('../fonts/NunitoSans/NunitoSans-ExtraLightItalic.eot');
	src: local('Nunito Sans ExtraLight Italic'),
		local('NunitoSans-ExtraLightItalic'),
		url('../fonts/NunitoSans/NunitoSans-ExtraLightItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/NunitoSans/NunitoSans-ExtraLightItalic.woff') format('woff'),
		url('../fonts/NunitoSans/NunitoSans-ExtraLightItalic.ttf')
			format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito', sans-serif;
	src: url('../fonts/NunitoSans/NunitoSans-ExtraLight.eot');
	src: local('Nunito Sans ExtraLight'), local('NunitoSans-ExtraLight'),
		url('../fonts/NunitoSans/NunitoSans-ExtraLight.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/NunitoSans/NunitoSans-ExtraLight.woff') format('woff'),
		url('../fonts/NunitoSans/NunitoSans-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito', sans-serif;
	src: url('../fonts/NunitoSans/NunitoSans-BoldItalic.eot');
	src: local('Nunito Sans Bold Italic'), local('NunitoSans-BoldItalic'),
		url('../fonts/NunitoSans/NunitoSans-BoldItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/NunitoSans/NunitoSans-BoldItalic.woff') format('woff'),
		url('../fonts/NunitoSans/NunitoSans-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito', sans-serif;
	src: url('../fonts/NunitoSans/NunitoSans-ExtraBoldItalic.eot');
	src: local('Nunito Sans ExtraBold Italic'),
		local('NunitoSans-ExtraBoldItalic'),
		url('../fonts/NunitoSans/NunitoSans-ExtraBoldItalic.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/NunitoSans/NunitoSans-ExtraBoldItalic.woff') format('woff'),
		url('../fonts/NunitoSans/NunitoSans-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Nunito', sans-serif;
	src: url('../fonts/NunitoSans/NunitoSans-Italic.eot');
	src: local('Nunito Sans Italic'), local('NunitoSans-Italic'),
		url('../fonts/NunitoSans/NunitoSans-Italic.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/NunitoSans/NunitoSans-Italic.woff') format('woff'),
		url('../fonts/NunitoSans/NunitoSans-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}
