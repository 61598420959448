.acc-holder-field {
	display: flex;
	flex-direction: column;
	margin-top: 6px;
	column-gap: 15px;

	.label {
		white-space: nowrap;
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 900;
		color: $black;
	}

	.input-error {
		grid-column-start: 2;
	}

	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: min-content auto;
	}
}

.acc-holder-info {
	font-family: 'avenir', sans-serif;
	font-size: 12px;
	font-weight: 350;
	color: #9e9faf;
	display: block;
	padding-bottom: 10px;

	@media (min-width: 1200px) {
		margin: 0;
		padding-bottom: 0;
	}
}

.acc-names {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 45px;
}

%acc-text {
	font-family: serif;
	font-style: normal;
	color: #000;
	text-align: center;
}

.acc-main-title {
	@extend %acc-text;
	font-weight: 400;
	font-size: 13pt;
	line-height: 1.1;
	white-space: nowrap;

	span {
		@extend %acc-text;
		font-weight: 700;
		font-size: 23pt;
		line-height: 18px;
	}

	b {
		@extend %acc-text;
		font-weight: 700;
		font-size: 14pt;
		display: block;
		padding-top: 5px;
	}
}

.acc-sub-title {
	@extend %acc-text;
	font-weight: 700;
	font-size: 15pt;
	margin-bottom: 22px;
	display: block;
	white-space: nowrap;
}

.acc-sup-title {
	@extend %acc-text;
	font-weight: 700;
	font-size: 14pt;
	display: block;
}

.acc-notif {
	@extend %acc-text;
	font-weight: bold;
	font-size: 9.7pt;
	padding: 10px 0;
	display: block;
	letter-spacing: 0.9px;
}

.acc-table-container {
	padding: 0 10px;
	max-width: 100%;
	overflow-x: auto;
}

.acc-table {
	border: 2px solid #000;
	border-collapse: collapse;
	min-width: 840px;
	width: 100%;
}

.acc-tbody {
}

.acc-th {
	border: 0.5px solid #000;
}

.acc-tr {
}

.acc-tr-radio {
	.acc-error-message {
		text-align: left;
		padding-right: 0;
		padding-left: 12px;
	}
}

.acc-td-header {
	padding: 10px 18px 15px 18px;
	border: 0.5px solid #000;
	vertical-align: baseline;

	&:first-child {
		width: 1%;
	}
}

.acc-td {
	padding: 5px;
	border: 0.5px solid #000;
	vertical-align: baseline;
}

.acc-td-clear {
	padding: 4px 10px;
	vertical-align: baseline;

	.acc-string-field.acc-employer {
		padding-bottom: 20px;
	}
}

.acc-form-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 2px;
	width: fit-content;
	position: relative;
	padding-right: 12px;
}

.acc-form-select {
	width: 100% !important;
}

.acc-form,
.acc-form-select {
	display: flex;
	flex-direction: column;
	width: fit-content;
	position: relative;
	padding-right: 12px;

	.acc-label {
		width: fit-content;
	}

	.acc-error-message {
		display: none;
	}

	&.acc-error {
		.acc-error-message {
			display: block;
			text-align: left;
			padding-right: 0;
		}
	}
}

.acc-form-container {
	display: flex;
	flex-direction: column;
	gap: 2px;
	position: relative;
	width: 100%;
}

.acc-form-fixed {
	display: flex;
	position: relative;
	width: fit-content;
	flex-direction: column;
}

.acc-form-column {
	display: flex;
	position: relative;
	flex-direction: column;
}

.acc-form-multi {
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: 100%;
	position: relative;
}

.acc-label {
	display: flex;
	gap: 10px;
	width: calc(100% - 12px);
	@extend %acc-text;
	font-weight: 700;
	font-size: 14pt;
	white-space: nowrap;

	.acc-form-column & {
		flex-direction: column;
		align-items: flex-start;
		gap: 2px;
	}

	span {
		@extend %acc-text;
		font-weight: 400;
		font-size: 16px;
	}
}

.acc-input {
	font-family: sans-serif;
	font-weight: bold;
	font-size: 12px;
	font-style: normal;
	color: #000;
	background-color: #cae4ff;
	border: solid 1px #999;
	padding: 0 2px;
	display: block;
	width: 100%;
	height: 21px;
	min-height: 21px;

	&[type='password'] {
		font-size: 16px;
	}

	.acc-error & {
		border-color: #ff0000;
	}

	&:disabled {
		background-color: #ebebe4;
	}

	&:focus-visible {
		border-color: transparent;
		box-shadow: 0 0 1px 1px #0000ff;
		border-radius: 2px;
	}

	.acc-form-fixed & {
		width: 40em;
	}
}

.acc-form-required {
	position: absolute;
	top: 0;
	right: 0;
	font-family: serif;
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	color: #ff0000;

	.acc-form-column & {
		top: 23px;
	}
}

.acc-error-message {
	display: block;
	font-family: serif;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	color: #ff0000;
	text-align: right;
	padding-right: 12px;
}

.acc-select,
.acc-select option {
	width: 100%;
	font-family: sans-serif;
	font-weight: 400;
	font-size: 13px;
	font-style: normal;
	color: #000;
	cursor: pointer;
	min-width: 125px;
	height: 21px;
	min-height: 21px;

	.acc-error & {
		border-color: #ff0000;
	}
}

.acc-select-label {
	display: flex;
	gap: 10px;
	width: 100%;
	@extend %acc-text;
	font-weight: 700;
	font-size: 16px;
}

.acc-check-label {
	display: flex;
	align-items: flex-start;
	gap: 5px;
	@extend %acc-text;
	font-weight: 400;
	font-size: 14pt;
	width: 100%;
	text-align: left;
	cursor: pointer;

	input {
		margin-top: 5px;
		width: 13px;
		min-width: 13px;
		height: 13px;
		position: relative;

		&:checked {
			&::before {
				content: '';
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				left: 0;
				top: 0;
				width: 13px;
				min-width: 13px;
				height: 13px;
				background-color: #f15635;
				border-radius: 50%;
			}
		}
	}

	&:not(:last-child) {
		margin-bottom: 2px;
	}
}

.acc-check {
}

.acc-title-type {
	display: block;
	text-align: left;
	@extend %acc-text;
	font-weight: 700;
	font-size: 14pt;
	padding: 2px 0;
}

.acc-part {
	border: none;
	text-align: left;
	padding: 2px 0;
}

.acc-part-num {
	@extend %acc-text;
	font-weight: bold;
	font-size: 12pt;
	background-color: #000;
	color: #fff;
	padding: 0 2px;
	margin: 0 2px;
}

.acc-part-title {
	@extend %acc-text;
	font-weight: bold;
	font-size: 12pt;
}

.acc-paragraph {
	display: block;
	@extend %acc-text;
	font-weight: 700;
	font-size: 14pt;
	margin-bottom: 10px;
	text-align: left;
	width: 85%;
}

.acc-divider {
	display: block;
	@extend %acc-text;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 16px;
	text-align: left;
	margin: 5px 0;
}

.acc-string-field {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 2px;
	width: 100%;
	margin-top: 10px;

	.acc-error-message {
		display: none;
	}

	&.acc-error {
		.acc-error-message {
			display: block;
			text-align: left;
			padding-right: 0;
		}
	}

	&.acc-security {
		input {
			width: 26px;
			min-width: 26px;
			height: 22px;
			padding: 1px 2px;

			&:first-child {
				width: 36px;
				min-width: 36px;
			}

			&:last-child {
				min-width: 46px;
				width: 46px;
			}
		}
	}

	&.acc-employer {
		.acc-toggle-section {
			input {
				&:first-child {
					width: 55px;
				}
			}
		}

		input {
			height: 22px;
			padding: 1px 2px;

			&:first-child {
				min-width: 26px;
				width: 26px;
			}

			&:last-child {
				min-width: 91px;
				width: 91px;
			}
		}

		.acc-error-message {
			margin-top: 20px;
		}
	}
}

.acc-list-title {
	display: block;
	text-align: left;
	@extend %acc-text;
	font-weight: 700;
	font-size: 10pt;
}

.acc-list {
	list-style: decimal;
	padding-left: 20px;
}

.acc-list-item {
	@extend %acc-text;
	font-weight: 700;
	font-size: 14pt;
	text-align: left;
}

.acc-list-info {
	display: block;
	text-align: left;
	@extend %acc-text;
	font-weight: 700;
	font-size: 16px;
}

.acc-initial {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	margin: 5px;
	border: 0.5px solid #000;
	padding: 10px;

	.acc-form-container {
		width: fit-content;
		padding-right: 12px;
	}

	.acc-label {
		font-size: 10pt;
		font-weight: 700;
		gap: 0;
		align-items: center;
	}

	.acc-input {
		width: 36px;
		min-width: 36px;
		height: 18px;
	}
}

.acc-initial-notif {
	display: block;
	@extend %acc-text;
	font-size: 10pt;
	font-weight: 700;
	padding-left: 4px;
}

.acc-studio-title {
	display: block;
	width: 100%;
	font-family: 'avenir', sans-serif;
	font-weight: 900;
	font-size: 16px;
	color: #2c2e45;
	padding-top: 18px;
	padding-bottom: 20px;
	border-top: 1px solid #c7c7c7;
}

.acc-phone {
	padding-right: 5px;

	input {
		width: 35px;

		&:last-child {
			width: 55px;
		}
	}
}

.acc-ext {
	position: relative;
	width: fit-content;
	padding-right: 12px;

	input {
		width: 30px;
	}

	.acc-error-message {
		text-align: left;
		padding-right: 0;
	}
}

.acc-zip {
	position: relative;
	width: fit-content;
	padding-right: 12px;

	.acc-label {
		width: fit-content;
	}

	input {
		width: 50px;
	}

	.acc-error-message {
		text-align: left;
		padding-right: 0;
	}
}

.acc-td-multi {
	padding-right: 36px;
}

.acc-user-list {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	@media (min-width: $breakpoint-sm) {
		flex-direction: row;
		align-items: center;
		gap: 50px;
	}
}

.acc-user-item {
	display: flex;
	align-items: center;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: $black;
	gap: 10px;
	margin-bottom: 12px;

	b {
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight: 900;
	}

	a {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: 900;
		color: $blue;

		&:hover {
			text-decoration: underline;
		}
	}
}

.acc-user-names {
	max-width: 20vw;
	overflow: hidden;

	span,
	a {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.acc-user-form {
	.label,
	.label-grid {
		padding-left: 0;
	}

	.label {
		margin-bottom: 2px;
	}

	.input-form-grid,
	.acc-user-select {
		width: 100%;
	}
}

.acc-user-select {
	.label {
		font-family: 'avenir', sans-serif;
		font-size: 16px;
		font-weight: 900;
		color: $black;
	}
}

.acc-user-timezone {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	padding-bottom: 15px;

	@media (min-width: $breakpoint-sm) {
		display: grid;
		align-items: center;
		grid-template-columns: 1fr 2fr 1fr;
	}

	@media (min-width: $breakpoint-xl) {
		max-width: 1096px;
	}
}

.acc-user-address {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	margin-bottom: 10px;
	column-gap: 20px;
	row-gap: 10px;

	.input-form-grid {
		&:first-child,
		&:nth-child(2) {
			grid-column: span 2;

			@media (min-width: $breakpoint-sm) {
				grid-column: auto;
			}
		}
	}

	@media (min-width: $breakpoint-sm) {
		grid-template-columns: 4.5fr 1.3fr 1fr 0.5fr;
		gap: 20px;
	}

	@media (min-width: $breakpoint-xl) {
		max-width: 1096px;
	}
}

.acc-user-fields {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 10px;
	margin-bottom: 15px;

	@media (min-width: $breakpoint-sm) {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		margin-right: 24vw;
	}

	@media (min-width: $breakpoint-xl) {
		max-width: calc(1096px - 24vw);
	}
}

.acc-settings {
	margin-bottom: 20px;
	border-bottom: 1px solid $silver;

	@media (min-width: $breakpoint-sm) {
		padding-left: 8px;
	}

	.order-table-container {
		height: fit-content;
		max-height: 23vw;
	}
}

.acc-item-btn {
	width: 100%;

	button {
		padding: 0 3.5vw;
	}

	@media (min-width: $breakpoint-sm) {
		margin-left: auto;
		width: auto;

		button {
			width: 100%;
			padding: 0 20px;
		}
	}
}

.acc-settings-image {
	display: flex;
	flex-direction: column;
	padding-bottom: 10px;
	gap: 20px;

	img {
		width: 115px;
		object-fit: contain;
		object-position: center;
		margin: 0 auto;
	}

	.org-cover-mock {
		width: 115px;
		height: 115px;
		min-width: 115px;
	}

	@media (min-width: $breakpoint-sm) {
		flex-direction: row;
	}
}

.acc-image-fields {
	width: 100%;
}

.acc-user-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 12px;
	width: 12px;
}

.acc-add-user {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	button {
		width: auto;
		margin: 0;
		padding: 0 3.5vw;
	}
}

.acc-user-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	gap: 12px;
	margin-bottom: 15px;

	@media (min-width: 768px) {
		justify-content: space-between;
		margin-bottom: 13px;
		margin-top: 10px;
	}
}

.acc-user-container {
	width: 100%;
	max-width: 100%;
	background-color: #fff;
	padding: 14px 12px;
	height: fit-content;
	min-height: calc(100vh - 90px);

	@media (min-width: $breakpoint-sm) {
		min-width: fit-content;
		max-width: unset;
		padding: 8px 10px 20px 10px;
	}
}

.acc-toggle-section {
	position: relative;
}

.acc-toggle-container {
	position: relative;

	.acc-toggle-btn {
		right: 0;

		@media (min-width: 1200px) {
			right: 31%;
		}
	}
}

.acc-toggle-btn {
	position: absolute;
	top: calc(100% + 4px);
	right: 0;
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 900;
	color: $blue;
	cursor: pointer;
}

.acc-table-email {
	font-size: 14px;
	font-weight: 350;
	color: $grey;
}

.acc-user-image {
	width: 20px;
	min-width: 20px;
	height: 20px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	object-fit: cover;
	object-position: center;
}

.acc-user-modal {
	padding: 0 0 0 30px;
}

.acc-user-modal-container {
	display: grid;
	grid-template-columns: 1fr;
	gap: 22px;
	padding-top: 8px;
}

.acc-user-domain-container {
}

.acc-user-domain {
	display: flex;
	align-items: center;
	gap: 5px;
	width: 100%;
	padding-left: 3px;

	.input-form-grid {
		width: 100%;
	}

	.grid-header {
		display: none;
	}

	span {
		display: block;
		font-family: 'avenir', sans-serif;
		font-size: 14px;
		font-weight: 350;
		color: $black;
	}
}

.acc-user-btns {
	width: 95%;
	margin: 0 auto;
	margin-top: 25px;
	padding-right: 30px;
}

.acc-email-modal {
	display: grid;
	grid-template-columns: 1fr;
	gap: 12px;
	padding: 0 10px;
	margin-bottom: 25px;
	margin-top: 8px;

	.input-form-grid {
		gap: 5px;
	}

	.label-grid {
		padding-left: 0;
	}
}
