.promotion-container {
	width: 100%;
	max-width: 100%;
	background-color: #fff;
	padding: 5px 10px;
	height: fit-content;
	min-height: calc(100vh - 90px);

	@media (min-width: $breakpoint-sm) {
		min-width: fit-content;
		max-width: unset;
		padding: 2px 15px 20px 15px;
	}
}

.promotion-details-container {
	width: 100%;
	max-width: 100%;
	background-color: #fff;
	padding: 12px 10px;
	height: fit-content;
	min-height: calc(100vh - 90px);

	@media (min-width: $breakpoint-sm) {
		min-width: fit-content;
		max-width: unset;
		padding: 15px 10px 20px 10px;
	}

	.org-details-header {
		margin-bottom: 8px;
		letter-spacing: -0.7px;
		align-items: flex-start;

		@media (min-width: $breakpoint-sm) {
			align-items: center;
		}
	}
}

.promotion-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-bottom: 20px;
	gap: 20px;
}

.promotion-header-list {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	padding-top: 6px;
	width: 100%;

	@media (min-width: $breakpoint-sm) {
		justify-content: flex-start;
		padding-left: 25px;
		padding-top: 28px;
		gap: 50px;
	}
}

.promotion-header-item {
	display: flex;
	align-items: center;
	gap: 5px;
	font-family: 'avenir', sans-serif;
	font-size: 18px;
	font-weight: 350;
	color: $black;
	border-bottom: 3px solid transparent;
	padding-bottom: 5px;
	cursor: pointer;

	span {
		display: block;
		font-family: 'avenir', sans-serif;
		font-size: 12px;
		font-weight: 350;
	}

	&:hover:not(.is-disabled) {
		border-color: #fec52e;
	}

	&.is-disabled {
		color: $silver;
		cursor: default;
	}

	&.is-active {
		font-weight: 900;
		border-color: #fec52e;
	}
}

.promotion-header-btn {
	width: 100% !important;
	min-width: fit-content !important;
	margin: 0 !important;
	margin-top: 25px !important;

	@media (min-width: $breakpoint-sm) {
		margin-top: 0 !important;
		width: 335px !important;
	}
}

.promotion-details-wrapper {
	border: 1px dashed $silver;
	padding: 10px;
	width: 100%;
}

.promotion-details-form {
	display: grid;
	grid-template-rows: 1fr;
	gap: 10px;
	width: 100%;

	@media (min-width: $breakpoint-sm) {
		gap: 20px;
		width: 33%;
		min-width: 360px;
	}

	.input-form-autorow {
		display: grid;
		grid-template-columns: 0.4fr 1fr;

		&:first-child {
			.label-grid {
				font-family: 'avenir', sans-serif;
				font-size: 16px;
				font-weight: 900;
				color: $black;
			}
		}

		.label-grid {
			font-family: 'avenir', sans-serif;
			font-size: 14px;
			font-weight: 350;
			color: $black;
			padding-top: 3px;
			padding-left: 0;
		}

		.suplabel-grid {
			display: none;
		}
	}
}

.promotion-label,
.label,
.label-grid {
	font-family: 'avenir', sans-serif;
	font-size: 14px;
	font-weight: 350;
	color: $black;
	padding-left: 0;
}

.promotion-multi-form {
	display: grid;
	align-items: flex-start;
	grid-template-columns: 0.4fr 1fr;
	width: 100%;
	gap: 10px;

	.input-form-autorow {
		display: flex;
		align-items: center;
	}

	.grid-header {
		display: none;
	}
}

.promotion-details-multi {
	display: grid;
	align-items: flex-start;
	grid-template-columns: 0.6fr 1fr;
	gap: 10px;
	width: 100%;

	.grid-error {
		width: 279%;
	}
}

.promotion-select-form {
	display: grid;
	align-items: center;
	grid-template-columns: 0.4fr 1fr;
	width: 100%;
	gap: 0 10px;

	.input-error {
		grid-column: auto;
		grid-column-start: 2;
	}
}

.promotion-details-footer {
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	width: 100%;
	gap: 10px;
	border-top: 1px solid $silver;
	margin-top: 13px;
	padding-top: 10px;

	@media (min-width: $breakpoint-sm) {
		display: flex;
		justify-content: center;
		gap: 6rem;
	}

	button {
		margin: 0 !important;
		min-width: fit-content !important;
	}

	.btn-primary {
		width: 100%;

		@media (min-width: $breakpoint-sm) {
			width: 220px !important;
		}
	}

	.btn-secondary {
		width: 100%;

		@media (min-width: $breakpoint-sm) {
			width: 156px !important;
		}
	}
}
