.btn {
	height: 38px;
	font-family: 'avenir', sans-serif;
}

.btn-primary {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-width: fit-content;
	height: 40px;
	margin: 0 auto;
	padding: 0 10px;
	background-color: #f15635;
	border: 1px solid #f15635;
	font-size: 16px;
	font-weight: 900;
	color: #2c2e45;
	border-radius: 2px;
	cursor: pointer;

	&:hover {
		background-color: #fec52e;
		border-color: #fec52e;
		color: #2c2e45;
	}

	&:focus {
		background-color: #2c2e45;
		border-color: #2c2e45;
		color: #f7f7f7;
		outline: none;
	}

	&:disabled {
		background-color: #e2e2e2;
		border-color: #e2e2e2;
		color: #f7f7f7;
		cursor: default;
	}
}

.btn-secondary {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-width: fit-content;
	height: 40px;
	padding: 0 10px;
	font-size: 14px;
	font-weight: 350;
	color: #2c2e45;
	border: 1px solid #9e9faf;
	border-radius: 2px;
	background-color: #f7f7f7;
	white-space: nowrap;
	cursor: pointer;

	&:hover {
		background-color: #e2e2e2;
	}

	&:focus {
		background-color: #c7c7c7;
	}

	&:disabled,
	&.is-disabled {
		background-color: #f7f7f7;
		border-color: #e2e2e2;
		color: #e2e2e2;
		cursor: default;
	}
}

.btn-colorpicker {
	border: 0.5px solid #9e9faf;
	background-color: #fff;
	font-size: 12px;
	font-weight: 350;
	color: #2c2e45;
	border-radius: 2px;
	padding: 2px 5px;
	height: 25px;
	width: 100%;
	cursor: pointer;
	align-self: flex-end;

	@media (min-width: 768px) {
		align-self: center;
	}

	i {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80%;
		height: 100%;
		margin: 0;

		&::after {
			content: '';
			display: flex;
			align-items: center;
			justify-content: center;
			background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.87011 5.67624L5.50011 1.09424M1.16211 5.67724L5.53211 1.09424' stroke='%232C2E45' stroke-width='1.1' stroke-linecap='round'/%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			transform: rotate(180deg);
			width: 10px;
			min-width: 10px;
			height: 10px;
			position: absolute;
			left: 110%;
		}
	}
}

.btn-container-decrease {
	display: grid;
	grid-template-columns: 1fr 0.58fr;
	gap: 30px;
}
